// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
// import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
// import { List, arrayMove } from 'react-movable';
import {ajaxPerso, fetchDataSet, fetchMenu, getCollectionItemCount, fetchApiData, menuOrganized, menuOrganizedChilds, fetchMapping, fetchAutoCreatorMapping, fetchAutoCreatorFetchFeedProperties} from "../fnc.js"
import store from 'store2';
import Select from 'react-select';
import Loading from './ctn/Loading';
const refConst = require("../constants.js").v

refConst.styleList.container = (provided) => ({
	...provided,
	width: "80%",
	minWidth: "250px"
})

export default function AutoCreate_settings(props){

	let sectionOpenMapping = store("sectionOpenMapping")
	let levelListOpenedHistory = store("levelListOpenedHistory")

	const [eBayOpenCatEditor, setEBayOpenCatEditor] = useState(null);
	const [suggestions, setSuggestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refreshView, setRefreshView] = useState(0);
	const [fullListCatIdFrom, setFullListCatIdFrom] = useState([]);
	const [catFromTo, setCatFromTo] = useState({id_section: null, from: [], to: []});
	const [catChanged, setCatChanged] = useState({}); // id_category => {id_category => null, eio_id_associated => null}
	const [levelListOpened, setLevelListOpened] = useState(levelListOpenedHistory? levelListOpenedHistory : []);
	const [onlyShow3M, setOnlyShow3M] = useState(false);
	const [onlyShowEioProducts, setOnlyShowEioProducts] = useState(false);
	const [shopItemCountPerCollection, setShopItemCountPerCollection] = useState(null);

	const [idSectionOpened, setIdSectionOpened] = useState(sectionOpenMapping? sectionOpenMapping : null);
	
	useEffect(() => {
		$('.tooltipInfo').tooltip({
			placement: "right",
			template:  '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-info-ebay"></div></div>',
		}).tooltip('update')
	})

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		store("sectionOpenMapping", catFromTo.id_section)
		setCatChanged({})

		props.loading(false)


	}, [catFromTo.id_section])

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		store("levelListOpenedHistory", levelListOpened)

	}, [levelListOpened])

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		let listOptionBrands = [],
			selectedBrand = null
		catFromTo.from.map((o, pos) => {

			listOptionBrands.push({
				value: pos,
				label: o.vendor
			})

			if(catFromTo.pos_brand_selected !== null && catFromTo.pos_brand_selected == pos)
				selectedBrand = listOptionBrands[listOptionBrands.length-1]
		})

		console.info("catFromTo", catFromTo);
		console.info("selectedBrandselectedBrand", selectedBrand);

		if(!selectedBrand || !selectedBrand.label)	return;

		fetchAutoCreatorFetchFeedProperties({
			"vendor": selectedBrand.label
		}, null, (resFeed) => {
			console.info(`Fetched fetchAutoCreatorFetchFeedProperties`, resFeed);

			if(resFeed.success){

				catFromTo.to = resFeed.data
				setCatFromTo(catFromTo)
				setRefreshView(refreshView+1)
			}
		})

	}, [catFromTo.pos_brand_selected])

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		if(sectionOpenMapping){
			loadSection(sectionOpenMapping)
		}

	}, [])

	const loadSection = (idSection) => {

		props.loading(true)
		setFullListCatIdFrom([])
		setIdSectionOpened(idSection)
		loadShopifyitemCountPerCollection()

		switch(idSection){
			case 5:
				load3mToShopify(idSection, refConst.id_store_eio, 'category_3M_to_eio')
			break;
			case 7:
				load3mToShopify(idSection, refConst.id_store_hausfTools, 'category_3M_to_haus')
			break;
			case 6:
				loadAutoCreatorToFeedData(idSection)
			break;
			case 1:
				loadEioToEbay(idSection)
			break;
			case 2:
				loadHausToEio(idSection)
			break;
			case 3:
				loadDatasetHausToEio(idSection)
			break;
			case 8:
				loadReferralFeeWalmart(idSection)
			break;
			case 9:
				loadWalmartCategoriesV4(idSection)
			break;
		}
	}

	const loadShopifyitemCountPerCollection = () => {

		getCollectionItemCount({
			"shop": refConst.id_store_eio
		}, null, (resColItemCount) => {
			console.info(`Fetched getCollectionItemCount [shop ID] for ${refConst.id_store_eBay_EIO}`, resColItemCount);
			if(!resColItemCount.errors){

				setShopItemCountPerCollection(resColItemCount.data)
			}
		})
	}

	const load3mToShopify = (idSection, idShopifyStore, keyMapping) => {

		fetchApiData("autoCreator", "get_autoCreator_menu", {
			"id_product_brand": 3
		}, null, (resFrom) => {
			console.info(`Fetched fetchMenu [product brand] for ${3} store: ${idShopifyStore}`, resFrom);
			if(resFrom.success){

				let fullListCatIdFromBuild = []
				resFrom.data.forEach(o => {
					if(o.has_product_in)
						fullListCatIdFromBuild.push(o.id_category)
				});
				setFullListCatIdFrom(fullListCatIdFromBuild.sort())

				let menuFrom = menuOrganizedChilds(resFrom.data)
				console.info("menuFrommenuFrom", menuFrom);

				if(idShopifyStore)
					fetchMenu({
						"shop": idShopifyStore
					}, null, (resTo) => {
						console.info(`Fetched fetchMenu [shop ID] for ${idShopifyStore}`, resTo);
						if(resTo.success){

							/* resTo.data = resTo.data.filter(o => {
								if(o.site == "eio-v2")
									return o
							}) */
			
							let menuTo = menuOrganized(resTo.data)
							console.info('menumenu', menuTo);
			
							getMappingData([
								keyMapping
							], (mappingData) => {
			
								console.info('Res ' + keyMapping, mappingData);
					
								menuTo.forEach(c => {
									c.from_id_list_associated = []
									mappingData.some(d => {
										if(c.last.id_category == d.to){
											// console.info(`catTo ${c.last.id_category} -> ${d.to}`);
											c.from_id_list_associated.push(d.from)
										}
									})
								})


								/* setCatFrom(menuFrom)
								setCatTo(menuTo) */
								setCatFromTo({
									"id_section": idSection,
									"from": menuFrom,
									"to": menuTo
								})
							})
						}
					})
			}
		})
	}

	const loadAutoCreatorToFeedData = (idSection) => {

		fetchAutoCreatorMapping({
			// "id_brand": 3
		}, null, (resMappingList) => {
			console.info(`Fetched fetchAutoCreatorMapping`, resMappingList);

			if(resMappingList.success){

				let pos_brand_selected = null
				if(store("brandMappingProps") !== null)
					pos_brand_selected = store("brandMappingProps")

				console.info("pos_brand_selected2", pos_brand_selected);
				console.info("pos_brand_selected22", resMappingList.data[pos_brand_selected]);

				let listOfActualValueToAdd = []
				if(pos_brand_selected !== null){
					
					for (const propEio in resMappingList.data[pos_brand_selected].main_prop_mapping) {
						if (Object.hasOwnProperty.call(resMappingList.data[pos_brand_selected].main_prop_mapping, propEio)) {

							if(resMappingList.data[pos_brand_selected].main_prop_mapping[propEio]){
								listOfActualValueToAdd[propEio] = {
									constant_eio_property: propEio,
									feed_property: resMappingList.data[pos_brand_selected].main_prop_mapping[propEio]
								}
							}
						}
					}
					console.info("listOfActualValueToAddlistOfActualValueToAdd", listOfActualValueToAdd);
					setCatChanged(listOfActualValueToAdd)
				}

				setCatFromTo({
					"id_section": idSection,
					"from": resMappingList.data,
					"to": [],
					"pos_brand_selected": pos_brand_selected
				})
			}
		})
	}

	const loadSearchSection = (idSection) => {

		let listSelects = [],
			sectionValidFor = [5]

		if(sectionValidFor.indexOf(idSection) === -1)	return ""
		if(catFromTo.from.length === 0)	return "";

		recursive_menu_list(listSelects, catFromTo.from, 1)

		/* catFrom.map(lvl1 => {
			lvl1.lvl = 1
			lvl1.inlineTitle = lvl1.name
		})*/

		console.info("List", listSelects);
		console.info("levelListOpened", levelListOpened);

		return <div className="d-flex flex-row justify-content-start pb-2 pl-3 pr-3 bg-light border mb-3 sticky-top">{listSelects}</div>
	}

	const findObjectFromChilds = (idCategory) => {

		let catFound = null

		if(catFromTo.from.length === 0)	return "";

		catFromTo.from.some(lvl1 => {

			catFound = recursive_cat_founder(lvl1, idCategory)
			console.info("result:", catFound);
			return catFound;
		})
		return catFound
	}

	const recursive_cat_founder = (currentObj, idCategory) => {

		let catFound = null

		if(!currentObj.childs)	return currentObj

		if(currentObj.id_category == idCategory){
			console.info(`${currentObj.id_category} -> ${idCategory}: ${currentObj.id_category == idCategory}`);
			catFound = currentObj
		}else{
			
			if(currentObj.childs.length > 0)
				currentObj.childs.some(nextLvl => {
					let found = recursive_cat_founder(nextLvl, idCategory)
					if(found){
						catFound = found
						return catFound;
					}
				})
		}
		return catFound
	}

	const recursive_menu_list = (optionsList, catList, level) => {

		let listOptions = [],
			selectedValue = null
		if(catList.length > 0){

			let hasChilds = false

			catList.forEach(c => {

				// If all the child from this level have Zero childs, then we do not show
				if(c.childs.length > 0)
					hasChilds = true

				listOptions.push({
					value: c.id_category,
					label: `${c.name} (childs: ${recursive_count_childs(c)})`
				})
				if(levelListOpened[level-1] && levelListOpened[level-1] == c.id_category)
					selectedValue = c
			})

			if(hasChilds)
				optionsList.push(<div className="p-1" key={`${level}_lvlSection`}>
					{/* <div className="mr-3">{currentObj.lvl} - {currentObj.inlineTitle} childs: {currentObj.childs.length}</div> */}
					<div className="mr-3">Level {level}</div>
					<div>
						<Select
							isClearable
							styles={refConst.styleList}
							className="w-50"
							onChange={(selectedOption) => {
								console.info('selectedOption', selectedOption);
								if(selectedOption === null){
									let deleteFrom = level - 1
									for (let i = deleteFrom; i < levelListOpened.length; i++) {
										levelListOpened.splice(i, 1)
									}
								}else{
									levelListOpened[level-1] = parseInt(selectedOption.value)
								}
								setLevelListOpened(levelListOpened.slice(0))
							}}
							options={listOptions}
							// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.eio_id_associated? [{value: o.eio_id_associated, label: o.eio_id_associated}] : null) }
							value={ (levelListOpened[level-1] && selectedValue? [{value: levelListOpened[level-1], label: selectedValue.name}] : null) }
							placeholder={"category section"}
							isSearchable={true}
							isMulti={false}
						/>
					</div>
				</div>)

			if(selectedValue){
				recursive_menu_list(optionsList, selectedValue.childs, level+1)
			}
		}
	}

	const recursive_count_childs = (currentCat) => {

		let totalCount = 0

		if(currentCat.childs.length){

			totalCount += currentCat.childs.length

			currentCat.childs.forEach(c => {
				if(c.childs.length > 0){
					totalCount += recursive_count_childs(c)
				}
			})
		}
		return totalCount
	}

	const loadHausToEio = (idSection, callbackFnc) => {

		fetchMenu({
			"shop": refConst.id_store_eio
		}, null, (resTo) => {
			console.info(`Fetched fetchMenu [shop ID] for ${refConst.id_store_eio}`, resTo);
			if(resTo.success){

				let menuTo = menuOrganized(resTo.data)

				fetchMenu({
					"shop": refConst.id_store_hausfTools
				}, null, (resFrom) => {
					console.info(`Fetched fetchMenu [shop ID] for ${refConst.id_store_hausfTools}`, resFrom);
					if(resFrom.success){
		
						let menuFrom = resFrom.data
						// let menuFrom = menuOrganized(resFrom.data)

						console.info("menuFrommenuFrom", menuFrom);
		
						getMappingData([
							'category_haus_to_eio'
						], (mappingData) => {
		
							console.info('Res category_haus_to_eio', mappingData);
				
							menuFrom.forEach(c => {
								mappingData.forEach(d => {
									if(c.id_category === d.from){
											c.eio_id_associated = d.to
										return
									}
								})
							})
		
							/* setCatFrom(menuFrom)
							setCatTo(menuTo) */
							setCatFromTo({id_section: idSection, from: menuFrom, to: menuTo})
						})
					}
				})
			}
		})
	}

	const loadDatasetHausToEio = (idSection, callbackFnc) => {

		fetchDataset(refConst.id_store_eio, (resTo) => {
			console.info(`Fetched fetchDataset for ${refConst.id_store_eio}`, resTo);
			if(resTo.success){

				resTo.data.sort((obj1, obj2) => {
					if(obj1.title > obj2.title)
						return 1
					return -1
				})

				let menuTo = menuOrganized(resTo.data, "id", "title")

				fetchDataset(refConst.id_store_hausfTools, (resFrom) => {
					console.info(`Fetched fetchDataset for ${refConst.id_store_hausfTools}`, resFrom);
					if(resFrom.success){
		
						resFrom.data.sort((obj1, obj2) => {
							if(obj1.title > obj2.title)
								return 1
							return -1
						})

						let menuFrom = menuOrganized(resFrom.data, "id", "title")

						console.info("menuFrommenuFrom", menuFrom);
		
						getMappingData([
							'dataset_haus_to_eio'
						], (mappingData) => {
		
							console.info('Res dataset_haus_to_eio', mappingData);
				
							menuFrom.forEach(c => {
								mappingData.forEach(d => {
									if(parseInt(c.last.id) === d.from){
										c.eio_id_associated = d.to
										return
									}
								})
							})
		
							/* setCatFrom(menuFrom)
							setCatTo(menuTo) */
							setCatFromTo({id_section: idSection, from: menuFrom, to: menuTo})
						})
					}
				})
			}
		})
	}
	
	const loadReferralFeeWalmart = (idSection, callbackFnc) => {

		fetchApiData("walmart", "get_listing_categories", {}, null, (resFrom) => {
			console.info(`Fetched walmart [get_listing_categories] for ${idSection}`, resFrom);
			if(resFrom.success){

				let categoriesNameFrom = []
				resFrom.data.forEach(o => {
					categoriesNameFrom.push(o.category)
				});
				setFullListCatIdFrom(categoriesNameFrom)

				let keyMapping = "walmart_referral_fees"
				fetchApiData("walmart", "get_referrals", {}, null, (resTo) => {
					console.info(`Fetched walmart [get_referrals] for ${idSection}`, resTo);
					if(resTo.length > 0){
	
						getMappingData([
							keyMapping
						], (mappingData) => {

							console.info('Res ' + keyMapping, mappingData);
				
							// We complte the mapping from side if one if missing from the from list
							let propsInMapping = mappingData.map(o => {
								return o.from
							})

							categoriesNameFrom.map((catNameFrom, pos) => {
								if(propsInMapping.indexOf(catNameFrom) === -1){
									console.info("AAAAA", catNameFrom);
									console.info("AAAAA2", propsInMapping);
									mappingData.push({
										"from": catNameFrom,
										"to": null
									})
								}
							})

							setCatChanged(mappingData)

							/* setCatFrom(menuFrom)
							setCatTo(menuTo) */
							setCatFromTo({
								"id_section": idSection,
								"from": categoriesNameFrom,
								"to": resTo
							})
							setLoading(false);
							props.loading(false);
						})
					}
				})
			}
		})
	}

	const loadWalmartCategoriesV4 = (idSection, callbackFnc) => {

		fetchApiData("shopify", "fetchTypes", {
			shop: refConst.id_store_eio
		}, null, (resFrom) => {
			console.info(`Fetched shopify [get_types] for ${idSection}`, resFrom);
			if(resFrom.success){

				let categoriesNameFrom = resFrom.data
				setFullListCatIdFrom(categoriesNameFrom)

				let keyMapping = "walmart_categories_v4"
				fetchApiData("walmart", "get_api_file_subcategories", {}, null, (resTo) => {
					console.info(`Fetched walmart [get_api_file_subcategories] for ${idSection}`, resTo);
					if(resTo.data.length > 0){
	
						getMappingData([
							keyMapping
						], (mappingData) => {

							console.info('Res ' + keyMapping, mappingData);
				
							// We complte the mapping from side if one if missing from the from list
							let propsInMapping = mappingData.map(o => {
								return o.from
							})

							categoriesNameFrom.map((catNameFrom, pos) => {
								if(propsInMapping.indexOf(catNameFrom) === -1){
									mappingData.push({
										"from": catNameFrom,
										"to": null
									})
								}
							})

							setCatChanged(mappingData)

							/* setCatFrom(menuFrom)
							setCatTo(menuTo) */
							setCatFromTo({
								"id_section": idSection,
								"from": categoriesNameFrom,
								"to": resTo.data
							})
							setLoading(false);
							props.loading(false);
						})
					}
				})
			}
		})
	}

	const loadEioToEbay = (idSection, callbackFnc) => {

		fetchMenu({
			"shop": refConst.id_store_eio
		}, null, (resFrom) => {
			console.info(`Fetched fetchMenu [shop ID] for ${refConst.id_store_eio}`, resFrom);
			if(resFrom.success){

				let menuFrom = menuOrganized(resFrom.data)
				console.info("menuFrom", menuFrom);

				eioToEbayDataCreator(idSection, menuFrom)
			}
		})
	}

	const eioToEbayDataCreator = (idSection, menuFrom) => {
		fetchMenu({
			"shop": refConst.id_store_eBay_EIO
		}, null, (resTo) => {
			console.info(`Fetched fetchMenu [shop ID] for ${refConst.id_store_eBay_EIO}`, resTo);
			if(resTo.success){

				let menuTo = menuOrganized(resTo.data)
				console.info('menuFrom', menuFrom);
				console.info('menuTo', menuTo);

				getMappingData([
					'category_3M_to_eio'
				], (mappingData) => {

					console.info('Res category_3M_to_eio', mappingData);

					let listEioIdAttachedTo3m = mappingData.map(m => {
						return parseInt(m.to)
					})

					listEioIdAttachedTo3m.sort()

					console.info('listEioIdAttachedTo3m', listEioIdAttachedTo3m);

					getMappingData([
						'category_eio_to_eBay'
					], (mappingData) => {

						props.loading(false)
	
						console.info('Res category_eio_to_eBay', mappingData);
			
						menuFrom.forEach(c => { // EIO ID

							// Default value
							c.mappedTo3m = false

							mappingData.some(d => {

								// console.info(`${c.last.id_category} ------ ${d.from}`);

								// Add the info that show that this item is part of a 3M category mapped.
								if(listEioIdAttachedTo3m.indexOf(c.last.id_category) !== -1){
									c.mappedTo3m = true
								}

								if(c.last.id_category == d.from && d.to){ // ID base
									
									c.eBay_associated_inline = c.inline
									c.eBay_id_associated = parseInt(d.to)
									c.eBay_associated_is_motor = d.is_eBay_motor
									return true
								}
							})
						})
	
						/* setCatFrom(menuFrom)
						setCatTo(menuTo) */
						setCatFromTo({id_section: idSection, from: menuFrom, to: menuTo})
					})
				})
			}
		})
	}

	const getMappingData = (keys, callback) => {
		fetchMapping( keys, (res) => {
			console.info(`Fetched fetchMapping for ${keys}`, res);
			if(res.success){
				callback(res.data.length > 0? res.data[0].data : [])
			}
		})
	}

	const fetchDataset = (idStore, callback) => {

		fetchDataSet({
			"shop": idStore,
			// "level": 2,
			"justData": true,
		}, callback)
	}

	const control_category_data = (pairKey, callback) => {

		let listFilled = []

		console.info("control_category_data -> pairKey", pairKey);

		if(catFromTo.from)
			catFromTo.from.forEach(o => {

				let newVal = {}

				for (const key in pairKey) {
					if (Object.hasOwnProperty.call(pairKey, key)) {
						const newKeyName = pairKey[key];

						if(key in o && o[key]){
							newVal[newKeyName? newKeyName : key] = key == "last"?
								parseInt(o[key]["id_category" in o[key]? "id_category" : "id"])
								:
								o[key]
						}
					}
				}

				if(Object.keys(newVal).length === 2)
					listFilled.push(newVal)
			})

		/* if(listFilled.length === 0){
			props.info({error: "Please fill up at least one value from this dataset."})
		}else */
		callback(listFilled)
	}

	const control_category_changed = (pairKey, callback) => {

		let listFilled = []

		console.info("control_category_data -> pairKey", pairKey);

		if(catFromTo.from){
			for (const idCatFrom in catChanged) {
				if (Object.hasOwnProperty.call(catChanged, idCatFrom)) {
					const o = catChanged[idCatFrom];
					
					let newVal = {}
			
					for (const key in pairKey) {
						if (Object.hasOwnProperty.call(pairKey, key)) {
							const newKeyName = pairKey[key];
			
							if(key in o && o[key]){
								newVal[newKeyName? newKeyName : key] = o[key]
							}
						}
					}
			
					if(Object.keys(newVal).length === 2)
						listFilled.push(newVal)
				}
			}
		}

		/* if(listFilled.length === 0){
			props.info({error: "Please fill up at least one value from this dataset."})
		}else */
		callback(listFilled)
	}

	const submitChangeCategory = (pairKey, key, e) => {

		control_category_data(pairKey, (list) => {

			console.info("TEST DATA", JSON.stringify({
				"api": "eio",
				"trigger": "save_mapping_data",
				"keyMapping": key,
				"data": JSON.stringify(list),
			}));
	
			ajaxPerso( {
				"api": "eio",
				"trigger": "save_mapping_data",
				"keyMapping": key,
				"data": JSON.stringify(list),
			}, (res) => {
				console.info('Triggered `save_mapping_data`', res);
				if(res.success){
					props.info({success: "Category Mapping Saved"})
				}else
					props.info({error: res.error})
			})
		})
	}
	
	const submitChangePropertiesChanged = (pairKey, key, e) => {

		console.info("catChanged", catChanged);

		/* control_category_changed(pairKey, (list) => {

			console.info("listlist", list);
			console.info("fullListCatIdFrom", fullListCatIdFrom);

			ajaxPerso( {
				"api": "eio",
				"trigger": "save_mapping_data",
				"keyMapping": key,
				"propsFromList": JSON.stringify(fullListCatIdFrom),
				"addingType": "delta", // Will only save what was changed
				"data": JSON.stringify(list)
			}, (res) => {
				console.info('Triggered `save_mapping_data`', res);
				if(res.success){
					props.info({success: "Category Mapping Saved"})
				}else
					props.info({error: res.error})
			})
		}) */
	}

	const submitChangeCategoryChanged = (pairKey, key, e, listToSave) => {


		control_category_changed(pairKey, (list) => {

			console.info("catChanged", catChanged);
			console.info("listlist", list);
			console.info("fullListCatIdFrom", listToSave);

			if(listToSave.length === 0){

				props.info({error: "Please select at least one category to be able to save."})
				return false
			}

			ajaxPerso( {
				"api": "eio",
				"trigger": "save_mapping_data",
				"keyMapping": key,
				"propsFromList": JSON.stringify(fullListCatIdFrom),
				"addingType": "delta", // Will only save what was changed
				"data": JSON.stringify(listToSave)
			}, (res) => {
				console.info('Triggered `save_mapping_data`', res);
				if(res.success){
					props.info({success: "Category Mapping Saved"})
				}else
					props.info({error: res.error})
			})
		})
	}


	const fetchCategorySuggestions  = (qList, callback) => {
		if(!qList){
			console.warn("Please indicate a qList for fetchCategorySuggestions.");
			return false
		}
		setLoading(true);
		props.loading(true);
		ajaxPerso( Object.assign({
			"api": "ebay",
			"trigger": "categorySuggestions",
			"qList": [qList]
		}), callback)
	}

	const getSuggestion = (currentObj) => {
		let strOut = []
		console.info('suggestions', suggestions);
		console.info('currentObj', currentObj);
		
		if(suggestions.length > 0)
			suggestions.forEach((lineObj, posLine) => {

				let lineHtml = [],
					isMotorSection = false

				console.info('lineObj', lineObj);

				if("CategoryParentName" in lineObj.Category){
					if(typeof lineObj.Category.CategoryParentName === 'string'){
						lineObj.Category.CategoryParentID = [lineObj.Category.CategoryParentID];
						lineObj.Category.CategoryParentName = [lineObj.Category.CategoryParentName];
					}
					lineObj.Category.CategoryParentName.forEach((catParentName, pos) => {
						
						if(parseInt(lineObj.Category.CategoryParentID[pos]) === 6000){ // eBay Motors
							isMotorSection = true;
						}
						lineHtml.push(<span key={lineObj.Category.CategoryParentID[pos]}>{catParentName}&nbsp;&#62;&nbsp;</span>)
					})

					strOut.push(
						<div key={lineObj.Category.CategoryID + "_catSuggest"} onCompositionStart={() => {this.updateFeatureByCat(lineObj.Category.CategoryID)}}>
							<input onChange={(e) => {

								currentObj.eBay_associated_inline = lineObj.Category.CategoryParentName.join(" > ") + " > " + lineObj.Category.CategoryName
								currentObj.eBay_id_associated = parseInt(lineObj.Category.CategoryID)
								currentObj.eBay_associated_is_motor = isMotorSection
								setRefreshView(refreshView+1)
								
								return e
							}} defaultChecked={currentObj.eBay_id_associated === parseInt(lineObj.Category.CategoryID)? true : false} type="radio" name="inputCategory" value={lineObj.Category.CategoryID} className="mr-2"></input>
							{lineHtml}{lineObj.Category.CategoryName}
						</div>)
				}
			})
		
		return <div className={(suggestions.length > 0? "mt-4 mb-4 " : "") + " hasTooltip position-relative overflow-auto"} style={{overflowY: "auto"}}>
			<Loading loading={loading} centered={true}/>
			{strOut}
		</div>;
	}

	const updateCatSuggestionTrigger = (query, callback) => {

		// if(props.updateMode)	return false;

		fetchCategorySuggestions(query, (r) => {
			setLoading(false);
			props.loading(false);
			console.info('REQ fetchCategorySuggestions:', r);
			setSuggestions(r.length > 0 && r[0].status? r[0].data : [])
		})
	}

	const renderEbayCatSelection = (currentObj) => {

		currentObj.currentSearch = currentObj.last.name

		return <div className="m-auto text-wrap">
			<label htmlFor="forSearchSuggestion">Search for category suggestion</label>
			<span className="ml-3 tooltipInfo" data-html="true" title="Important:<br/>
				<ul>
					<li>Select carefully the most appropriate category</li>
					<li>You will NOT be able to move an item from eBay - US to eBay Motor since those are 2 diffetents website.</li>
				</ul>" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
			<div className="input-group hasTooltip">
				<form onSubmit={() => {

					console.info('Cables & Wire');
					updateCatSuggestionTrigger(currentObj.currentSearch)

				}} className="d-flex w-100 h-100">
					{/* <div className="custom-control custom-switch mr-2 d-flex flex-row align-items-center">
						<input type="checkbox" className="custom-control-input" id={`customSwitchMotor_${idSelected}`} checked={motorSectionSelected? true : false} onChange={(e) => { setMotorSectionSelected(!motorSectionSelected) }}/>
						<label className="custom-control-label noselect pointer" htmlFor={`customSwitchMotor_${idSelected}`}>eBay Motor</label>
					</div> */}
					<input type="text" className="form-control" defaultValue={currentObj.last.name} id="forSearchSuggestion" placeholder="Search Suggestion" onChange={(e) => {
						currentObj.currentSearch = e.target.value
						// setSearchSuggestion(e.target.value)        
					}}/>
					<div className="input-group-append">
						<button className="btn btn-outline-secondary" type="submit" onClick={(e) => {
							e.preventDefault()
							updateCatSuggestionTrigger(currentObj.currentSearch)
						}}>
							<FontAwesomeIcon icon={faSearch}/>
						</button>
					</div>
				</form>
			</div>
			<p className="p-0 m-0 mt-3">Just click to select and then once done, save it.</p>
			{ getSuggestion(currentObj) }
		</div>
	}

	const renderCategoryEioToEbayMapping = () => {

		let idSection = 1,
			dbKey = "category_eio_to_eBay",
			countAlreadyAsssociated = 0

		if(idSection != catFromTo.id_section)	return;

		console.info("catFromcatFromcatFromcatFrom", catFromTo.from);
		console.info("catTocatTocatTocatTocatTocatTo", catFromTo.to);

		catFromTo.from.forEach(o => {
			if("eBay_id_associated" in o){
				countAlreadyAsssociated++
			}
		})

		let catFromFiltered = catFromTo.from.filter(o => {
			if(onlyShow3M && o.mappedTo3m === true || onlyShow3M === false)	return o
		})
		
		if(onlyShowEioProducts && shopItemCountPerCollection && shopItemCountPerCollection.length > 0){

			// Only keep the one that have at least one product
			catFromFiltered = catFromTo.from.filter(o => {
				let found = false
				shopItemCountPerCollection.some(shopItem => {
					if(parseInt(shopItem.id_category) === parseInt(o.last.id_category)){
						o.number_item = parseInt(shopItem.number_item)
						found = true
						return true
					}
				})
				if(found)	return o
			})
			// Sort by number_item
			catFromFiltered.sort((a, b) => {
				if(a.number_item < b.number_item)	return 1
				return -1
			})
		}
		
		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Category Mapping from eio to eBay [{countAlreadyAsssociated}/{catFromFiltered.length}]
			</div>
			<div>
				<button className={(onlyShow3M? "active" : "") + " btn btn-outline-secondary"} onClick={() => {
					setOnlyShow3M(!onlyShow3M)
				}}>Show Only 3M</button>
				<button className={(onlyShowEioProducts? "active" : "") + " btn btn-outline-secondary ml-3"} onClick={() => {
					setOnlyShowEioProducts(!onlyShowEioProducts)
				}}>Show Only with EIO products</button>
			</div>
			<div className="">
				{
					catFromFiltered.filter(o => {
						if(onlyShow3M && o.mappedTo3m === true || onlyShow3M === false)	return o
					}).map(o => {
						return <div key={`${o.last.id}_${refConst.id_store_eio}_category_haus_to_eio`} className={"w-100 " + (eBayOpenCatEditor === o.last.id? "p-3 border rounded bg-light" : "")}>
							<div className={"p-2 d-flex flex-row align-items-center w-100 "}>
								<div className={"mr-3 w-50 text-wrap " + (eBayOpenCatEditor === o.last.id? "font-weight-bold" : "")}>
									{o.last.site? <span className="badge badge-pill badge-info mr-2">{o.last.site}</span> : "" }
									{o.mappedTo3m? <span className="badge badge-pill badge-danger mr-2">3M</span> : "" }
									{o.inline}
									{"number_item" in o? ` (${o.number_item})` : ""}
									{eBayOpenCatEditor === o.last.id? <button className="btn btn-warning ml-3" type="button" onClick={(e) => {
										e.preventDefault()
										setEBayOpenCatEditor(null)
									}}>close</button> : ""}
								</div>
								{
									"eBay_id_associated" in o? <span className="badge badge-pill badge-success p-3 mr-3">Currently selected: {o.eBay_associated_inline}</span> : ""
								}
								{
									eBayOpenCatEditor === o.last.id? '' : <button type="button" className="btn btn-primary" onClick={(e) => {setEBayOpenCatEditor(o.last.id)}}>open tool</button>
								}
							</div>
							<div className={"p-2 d-flex flex-row align-items-center w-75 " + (eBayOpenCatEditor === o.last.id? "" : "d-none")}>
								{
									eBayOpenCatEditor === o.last.id? renderEbayCatSelection(o) : ""
								}
							</div>
						</div>
					})
				}
			</div>
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategory({
					"last": "from",
					"eBay_id_associated": "to"
				}, dbKey, e)}}>Save</button>
			</div>
		</div>
	}

	/* const renderCategory3MToEbayMapping = () => {

		let idSection = 4,
			idProductBrand = 3,
			dbKey = "category_3M_to_eBay"

		if(!(idProductBrand in categoryPerBrand))	return ""

		console.info('categoryPerBrandcategoryPerBrand', categoryPerBrand);

		let brandListOptions = [],
			countAlreadyAsssociated = 0
		categoryPerBrand[idProductBrand].forEach(o => {
			brandListOptions.push({
				value: o.inline,
				label: o.inline
			})
			if("eBay_associated" in o)
				countAlreadyAsssociated++
		})

		console.info('eBayOpenCatEditor', eBayOpenCatEditor);
		
		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Category Mapping from 3M to eBay [{countAlreadyAsssociated}/{categoryPerBrand[idProductBrand].length}]<br/>
				This 3M category list include only the categories from our accesible items.
			</div>
			<div className="">
				{
					categoryPerBrand[idProductBrand].map(o => {

						return <div key={`${o.last.id}_${idProductBrand}_category_haus_to_eio`} className={"w-100 " + (eBayOpenCatEditor === o.last.id? "p-3 border rounded bg-light" : "")}>
							<div className={"p-2 d-flex flex-row align-items-center w-100 "}>
								<div className={"mr-3 w-50 text-wrap " + (eBayOpenCatEditor === o.last.id? "font-weight-bold" : "")}>
									{o.inline}
									{eBayOpenCatEditor === o.last.id? <button className="btn btn-warning ml-3" type="button" onClick={(e) => {
										e.preventDefault()
										setEBayOpenCatEditor(null)
									}}>close</button> : ""}
								</div>
								{
									"eBay_associated" in o? <span className="badge badge-pill badge-success p-3 mr-3">Currently selected: {o.eBay_associated}</span> : ""
								}
								{
									eBayOpenCatEditor === o.last.id? '' : <button type="button" className="btn btn-primary" onClick={(e) => {setEBayOpenCatEditor(o.last.id)}}>open tool</button>
								}
							</div>
							<div className={"p-2 d-flex flex-row align-items-center w-75 " + (eBayOpenCatEditor === o.last.id? "" : "d-none")}>
								{
									eBayOpenCatEditor === o.last.id? renderEbayCatSelection(o) : ""
								}
							</div>
						</div>
					})
				}
			</div>
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategory(null, categoryPerBrand[idProductBrand], dbKey, e)}}>Save</button>
			</div>
		</div>
	} */

	const recursive_section_childs = (catFromStarting, catListOut, inline) => {

		if(!catFromStarting.childs)	return catListOut

		if(catFromStarting && catFromStarting.childs.length > 0){
			catFromStarting.childs.forEach(c => {

				let newInline = []
				if(inline)	newInline.push(inline)
				newInline.push(c.name)

				catListOut.push({
					id_category: c.id_category,
					inline: newInline,
					has_product_in: c.has_product_in // For filtering later
				})

				recursive_section_childs(c, catListOut, newInline)
			})
		}
	}

	const renderCategory3mToEioMapping = () => {

		let idSection = 5,
			dbKey = "category_3M_to_eio"
		
		if(idSection != catFromTo.id_section)	return;
		
		let listOptions = [],
			listCatIdToName = {},
			catFromFiltered = null,
			catListOut = []

		console.info("catFromTo", catFromTo);

		// If something was selected
		if(levelListOpened[levelListOpened.length-1]){
			catFromFiltered = findObjectFromChilds(levelListOpened[levelListOpened.length-1])
			recursive_section_childs(catFromFiltered, catListOut, null) // catFromFiltered.name
		}else{
			catFromTo.from.forEach(cat => {
				recursive_section_childs(cat, catListOut, cat.name)
			})
		}

		// Create inline title
		catListOut = catListOut.filter((o) => {
			o.inline = o.inline.map((part, pos) => {
				return <span key={`${pos}_inline_category_3M_to_eio`}>{part}{pos < o.inline.length-1? <FontAwesomeIcon icon={faArrowRight} style={{
					marginLeft: "5px", 
					marginRight: "5px"
				}}/> : "" }</span>
			})
			if(o.has_product_in)	return o
			return false
		})

		/* catListOut = catListOut.filter( o => {
			if(o.has_product_in)	return o
			return false;
		}) */

		catListOut.sort((obj1, obj2) => {
			if(obj1.inline > obj2.inline)
				return 1
			return -1
		})
		

		console.info("catFromcatFromcatFromcatFrom", catFromTo.from);
		console.info("catTocatTocatTocatTocatTocatTo", catFromTo.to);
		console.info("catListOutcatListOut", catListOut);
		/* console.info("levelListOpened", levelListOpened);
		console.info("ssss", catFromFiltered);

		console.info("findObjectFromChildsfindObjectFromChilds", catFromFiltered);
		 */

		let catToFilled = {},
			catToFilledFiltered = {}

		catFromTo.to.forEach(o => {

			let sitePreInfo = "" // [v1]
			if(o.last.site == "haus-v2")
				sitePreInfo = "[v2] "

			listCatIdToName[o.last.id_category] = sitePreInfo + o.inline;
			listOptions.push({
				value: o.last.id_category,
				label: sitePreInfo + o.inline
			})
			if(o.from_id_list_associated.length > 0){
				o.from_id_list_associated.forEach(idFromAssociated => {

					catToFilled[idFromAssociated] = {
						value: o.last.id_category,
						label: sitePreInfo + o.inline
					}
				})
			}
		})

		// We keep only what's selected and is part of the current showing list.
		catListOut.forEach(o => {
			if(catToFilled[o.id_category]){
				catToFilledFiltered[o.id_category] = catToFilled[o.id_category]
			}
		})

		/* console.info("catToFilledFiltered", catToFilledFiltered);
		console.info("catToFilledFiltered2", catListOut.map(v => {
			return v.id_category
		})); */

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Category Mapping from 3M to EIO [{Object.keys(catToFilledFiltered).length}/{catListOut.length}]<br/>
				This 3M category list include only the categories from our accesible items.
			</div>
			<div className="">
				{
					catFromTo.from.length > 0 && catFromTo.to.length > 0? loadSearchSection(idSection) : ""
				}
				{
					catListOut.map((o, posLine) => {

						return <div key={`${o.id_category}_${refConst.id_store_hausfTools}_category_haus_to_eio`} className="p-2 d-flex flex-row align-items-center w-100">
							<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"} } */><span className="font-weight-bold smallText mr-1" style={{color: "#9b9b9b"}}>{posLine+1}</span>: {o.inline}</div>
							<div className="input-group w-50">
								<div className={`input-group-prepend ` + (posLine > 0 && catListOut[posLine-1].id_category in catChanged && catChanged[catListOut[posLine-1].id_category].shopify_id_associated? "" : "d-none")}>
									<button className="btn btn-outline-secondary" title="Copy the value from the line up" onClick={() => {
										catChanged[o.id_category] = {
											id_category: o.id_category,
											shopify_id_associated: catChanged[catListOut[posLine-1].id_category].shopify_id_associated,
										}
										setCatChanged(Object.assign({}, catChanged))
									}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-up position-relative" style={{top: "-3px"}} viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M4.854 1.146a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L4 2.707V12.5A2.5 2.5 0 0 0 6.5 15h8a.5.5 0 0 0 0-1h-8A1.5 1.5 0 0 1 5 12.5V2.707l3.146 3.147a.5.5 0 1 0 .708-.708l-4-4z"/>
										</svg>
									</button>
								</div>
								<Select
									isClearable
									styles={refConst.styleList}
									className=""
									onChange={(selectedOption) => {
										console.info('selectedOption', selectedOption);
										catChanged[o.id_category] = {
											id_category: o.id_category,
											shopify_id_associated: !selectedOption? null : selectedOption.value,
										}
										setCatChanged(Object.assign({}, catChanged))
									}}
									options={listOptions}
									// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.shopify_id_associated? [{value: o.shopify_id_associated, label: o.shopify_id_associated}] : null) }
									value={(
											/* Newly modified result */ catChanged[o.id_category]? [{value: catChanged[o.id_category].id_category, label: listCatIdToName[catChanged[o.id_category].shopify_id_associated]}]
											:
											/* Old result saved */ (catToFilledFiltered[o.id_category]? [catToFilledFiltered[o.id_category]] : null)
										)}
									placeholder={"category from eio"}
									isSearchable={true}
									isMulti={false}
								/>
							</div>
						</div>
					})
				}
			</div>
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategoryChanged({
					"id_category": "from",
					"shopify_id_associated": "to"
				}, dbKey, e, fullListCatIdFrom)}}>Save</button>
			</div>
		</div>
	}

	const renderCategory3mToHausMapping = () => {

		let idSection = 7,
			dbKey = "category_3M_to_haus"
		
		if(idSection != catFromTo.id_section)	return;
		
		let listOptions = [],
			listCatIdToName = {},
			catFromFiltered = null,
			catListOut = []

		console.info("catFromTo", catFromTo);

		// If something was selected
		if(levelListOpened[levelListOpened.length-1]){
			catFromFiltered = findObjectFromChilds(levelListOpened[levelListOpened.length-1])
			recursive_section_childs(catFromFiltered, catListOut, null) // catFromFiltered.name
		}else{
			catFromTo.from.forEach(cat => {
				recursive_section_childs(cat, catListOut, cat.name)
			})
		}

		// Create inline title
		catListOut = catListOut.filter((o) => {
			o.inline = o.inline.map((part, pos) => {
				return <span key={`${pos}_inline_category_3M_to_haus`}>{part}{pos < o.inline.length-1? <FontAwesomeIcon icon={faArrowRight} style={{
					marginLeft: "5px", 
					marginRight: "5px"
				}}/> : "" }</span>
			})
			if(o.has_product_in)	return o
			return false
		})

		/* catListOut = catListOut.filter( o => {
			if(o.has_product_in)	return o
			return false;
		}) */

		catListOut.sort((obj1, obj2) => {
			if(obj1.inline > obj2.inline)
				return 1
			return -1
		})
		

		console.info("catFromcatFromcatFromcatFrom", catFromTo.from);
		console.info("catTocatTocatTocatTocatTocatTo", catFromTo.to);
		console.info("catListOutcatListOut", catListOut);
		/* console.info("levelListOpened", levelListOpened);
		console.info("ssss", catFromFiltered);

		console.info("findObjectFromChildsfindObjectFromChilds", catFromFiltered);
		 */

		let catToFilled = {},
			catToFilledFiltered = {}

		catFromTo.to.forEach(o => {

			let sitePreInfo = "" // [v1]
			if(o.last.site == "eio-v2")
				sitePreInfo = "[v2] "

			listCatIdToName[o.last.id_category] = sitePreInfo + o.inline;
			listOptions.push({
				value: o.last.id_category,
				label: sitePreInfo + o.inline
			})
			if(o.from_id_list_associated.length > 0){
				o.from_id_list_associated.forEach(idFromAssociated => {

					catToFilled[idFromAssociated] = {
						value: o.last.id_category,
						label: sitePreInfo + o.inline
					}
				})
			}
		})

		// We keep only what's selected and is part of the current showing list.
		catListOut.forEach(o => {
			if(catToFilled[o.id_category]){
				catToFilledFiltered[o.id_category] = catToFilled[o.id_category]
			}
		})

		/* console.info("catToFilledFiltered", catToFilledFiltered);
		console.info("catToFilledFiltered2", catListOut.map(v => {
			return v.id_category
		})); */

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Category Mapping from 3M to HAUS [{Object.keys(catToFilledFiltered).length}/{catListOut.length}]<br/>
				This 3M category list include only the categories from our accesible items.
			</div>
			<div className="">
				{
					catFromTo.from.length > 0 && catFromTo.to.length > 0? loadSearchSection(idSection) : ""
				}
				{
					catListOut.map((o, posLine) => {

						return <div key={`${o.id_category}_${refConst.id_store_hausfTools}_category_haus_to_eio`} className="p-2 d-flex flex-row align-items-center w-100">
							<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"} } */><span className="font-weight-bold smallText mr-1" style={{color: "#9b9b9b"}}>{posLine+1}</span>: {o.inline}</div>
							<div className="input-group w-50">
								<div className={`input-group-prepend ` + (posLine > 0 && catListOut[posLine-1].id_category in catChanged && catChanged[catListOut[posLine-1].id_category].shopify_id_associated? "" : "d-none")}>
									<button className="btn btn-outline-secondary" title="Copy the value from the line up" onClick={() => {
										catChanged[o.id_category] = {
											id_category: o.id_category,
											shopify_id_associated: catChanged[catListOut[posLine-1].id_category].shopify_id_associated,
										}
										setCatChanged(Object.assign({}, catChanged))
									}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-up position-relative" style={{top: "-3px"}} viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M4.854 1.146a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L4 2.707V12.5A2.5 2.5 0 0 0 6.5 15h8a.5.5 0 0 0 0-1h-8A1.5 1.5 0 0 1 5 12.5V2.707l3.146 3.147a.5.5 0 1 0 .708-.708l-4-4z"/>
										</svg>
									</button>
								</div>
								<Select
									isClearable
									styles={refConst.styleList}
									className=""
									onChange={(selectedOption) => {
										console.info('selectedOption', selectedOption);
										catChanged[o.id_category] = {
											id_category: o.id_category,
											shopify_id_associated: !selectedOption? null : selectedOption.value,
										}
										setCatChanged(Object.assign({}, catChanged))
									}}
									options={listOptions}
									// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.shopify_id_associated? [{value: o.shopify_id_associated, label: o.shopify_id_associated}] : null) }
									value={(
											/* Newly modified result */ catChanged[o.id_category]? [{value: catChanged[o.id_category].id_category, label: listCatIdToName[catChanged[o.id_category].shopify_id_associated]}]
											:
											/* Old result saved */ (catToFilledFiltered[o.id_category]? [catToFilledFiltered[o.id_category]] : null)
										)}
									placeholder={"category from HAUS"}
									isSearchable={true}
									isMulti={false}
								/>
							</div>
						</div>
					})
				}
			</div>
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategoryChanged({
					"id_category": "from",
					"shopify_id_associated": "to"
				}, dbKey, e, fullListCatIdFrom)}}>Save</button>
			</div>
		</div>
	}

	const renderCategoryAutoCreateToFeedProp = () => {

		let idSection = 6,
			dbKey = ""
		
		if(idSection != catFromTo.id_section)	return;
		
		let listOptions = [],
			catListOut = [],
			catToFilledFiltered = {}

		let listOptionBrands = [],
			selectedBrand = null

		console.info("catFromTocatFromTo", catFromTo);

		catFromTo.from.map((o, pos) => {

			listOptionBrands.push({
				value: pos,
				label: o.vendor
			})

			if(catFromTo.pos_brand_selected !== null && catFromTo.pos_brand_selected == pos)
				selectedBrand = listOptionBrands[listOptionBrands.length-1]
		})

		catFromTo.to.map((propName, pos) => {

			listOptions.push({
				value: propName,
				label: propName
			})
		})

		let propsKeyValue = []

		if(catFromTo.pos_brand_selected !== null){

			let mappingList = catFromTo.from[catFromTo.pos_brand_selected].main_prop_mapping
			let datasetMappingFromFeed = catFromTo.from[catFromTo.pos_brand_selected].shopify_dataset_mapping
	
			for (const prop in mappingList) {
				if (Object.hasOwnProperty.call(mappingList, prop)) {
					propsKeyValue.push({
						"prop": prop,
						"value": mappingList[prop],
					})
				}
			}

			console.info("datasetMappingFromFeed",  catFromTo.from[catFromTo.pos_brand_selected]);

			for (const prop in datasetMappingFromFeed) {
				if (Object.hasOwnProperty.call(datasetMappingFromFeed, prop)) {
					propsKeyValue.push({
						"prop": prop,
						"value": datasetMappingFromFeed[prop],
					})
				}
			}
		}

		console.info("selectedBrand", selectedBrand);
		console.info("catChangedcatChanged", catChanged);

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Category Mapping from our auto crator template to our brand feed properties [{Object.keys(catToFilledFiltered).length}/{catListOut.length}]<br/>
			</div>
			<div className="d-flex flex-row justify-content-start align-items-center p-3 bg-light border sticky-top">
				<div className="mr-3 w-50 text-wrap">Brand list</div>
				<Select
						isClearable
						styles={refConst.styleList}
						className="w-50"
						onChange={(selectedOption) => {
							store("brandMappingProps", selectedOption.value)
							console.info('selectedOption', selectedOption);
							catFromTo.pos_brand_selected = selectedOption? selectedOption.value : null
							setRefreshView(refreshView+1) 
						}}
						options={listOptionBrands}
						// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.shopify_id_associated? [{value: o.shopify_id_associated, label: o.shopify_id_associated}] : null) }
						value={selectedBrand}
						placeholder={"Auto Creator brand"}
						isSearchable={true}
						isMulti={false}
					/>
			</div>
			{
				propsKeyValue.map((keyValue, posLine) => {

					console.info("keyValue", keyValue);
					let valueIn = catChanged[keyValue.prop]? catChanged[keyValue.prop].feed_property : (keyValue.value? keyValue.value : null)
					console.info("valueIn", valueIn);

					return <div key={`${keyValue.prop}_${refConst.id_store_hausfTools}_category_haus_to_eio`} className="p-2 d-flex flex-row align-items-center w-100">
						<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"}} */><span className="font-weight-bold smallText mr-1" style={{color: "#9b9b9b"}}>{posLine+1}</span>: {keyValue.prop}</div>
						<Select
							isClearable
							styles={refConst.styleList}
							className="w-50"
							onChange={(selectedOption) => {
								console.info('selectedOption', selectedOption);
								catChanged[keyValue.prop] = {
									constant_eio_property: keyValue.prop,
									feed_property: !selectedOption? null : selectedOption.value,
								}
								setCatChanged(Object.assign({}, catChanged))
							}}
							options={listOptions}
							// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.shopify_id_associated? [{value: o.shopify_id_associated, label: o.shopify_id_associated}] : null) }
							value={[{value: valueIn, label: valueIn}]}
							placeholder={"Feed Prop"}
							isSearchable={true}
							isMulti={false}
						/>
					</div>
				})
			}
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategoryChanged({
					"constant_eio_property": "from",
					"feed_property": "to"
				}, dbKey, e, fullListCatIdFrom)}}>Save</button>
			</div>
		</div>
	}

	const renderReferralWalmart = () => {

		let idSection = 8,
			dbKey = "walmart_referral_fees"
		
		if(idSection != catFromTo.id_section)	return;
		
		let countMapped = Array.isArray(catChanged)? catChanged.filter(o => {
			if(o.to)	return o
		}).length : 0

		console.info("catFromTocatFromTo", catFromTo);
		console.info("catChangedcatChanged", catChanged);

		let listOptions = []
		catFromTo.to.map((propName, pos) => {

			listOptions.push({
				value: propName,
				label: propName
			})
		})

		console.info('sadsdsad', catFromTo.mapping);

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Categories mapping to the referral fees [{countMapped}/{catChanged.length}]<br/>
			</div>
			{
				Array.isArray(catChanged)? catChanged.map((o, posLine) => {

					return <div key={`${o.from}_category_referralFee`} className="p-2 d-flex flex-row align-items-center w-100">
						<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"}} */><span className="font-weight-bold smallText mr-1" style={{color: "#9b9b9b"}}>{posLine+1}</span>: {o.from}</div>
						<Select
							isClearable
							styles={refConst.styleList}
							className="w-50"
							onChange={(selectedOption) => {
								console.info('selectedOption', selectedOption);
								catChanged[posLine] = {
									from: o.from,
									to: !selectedOption? null : selectedOption.value,
								}
								setCatChanged(catChanged.slice(0))
							}}
							options={listOptions}
							// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.shopify_id_associated? [{value: o.shopify_id_associated, label: o.shopify_id_associated}] : null) }
							value={[catChanged[posLine].to? {value: catChanged[posLine].to, label: catChanged[posLine].to} : null]}
							placeholder={"Feed Prop"}
							isSearchable={true}
							isMulti={false}
						/>
					</div>
				}) : ""
			}
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategoryChanged({
					"from": "from",
					"to": "to"
				}, dbKey, e, catChanged)}}>Save</button>
			</div>
		</div>
	}

	const renderCategoriesWalmart = () => {

		let idSection = 9,
			dbKey = "walmart_categories_v4"
		
		if(idSection != catFromTo.id_section)	return;
		
		let countMapped = Array.isArray(catChanged)? catChanged.filter(o => {
			if(o.to)	return o
		}).length : 0

		console.info("catFromTocatFromTo", catFromTo);
		console.info("catChangedcatChanged", catChanged);

		let listOptions = []
		catFromTo.to.map((val, pos) => {

			/* listOptions.push({
				value: o.subcategory,
				label: o.improvedName
			}) */
			listOptions.push({
				value: val,
				label: val
			})
		})

		console.info('sadsdsad', catFromTo.mapping);

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Categories mapping from EIO to Walmart API v4 [{countMapped}/{catChanged.length}]<br/>
			</div>
			{
				Array.isArray(catChanged)? catChanged.map((o, posLine) => {

					// Find the matched improvedName for the default value
					/* let defaultO = null
					if(catChanged[posLine].to){
						catFromTo.to.some(c => {
							if(c.subcategory == catChanged[posLine].to){
								defaultO = c
								return true
							}
						})
					} */

					return <div key={`${o.from}_category_referralFee`} className="p-2 d-flex flex-row align-items-center w-100">
						<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"}} */><span className="font-weight-bold smallText mr-1" style={{color: "#9b9b9b"}}>{posLine+1}</span>: {o.from}</div>
						<Select
							isClearable
							styles={refConst.styleList}
							className="w-50"
							onChange={(selectedOption) => {
								console.info('selectedOption', selectedOption);
								catChanged[posLine] = {
									from: o.from,
									to: !selectedOption? null : selectedOption.value,
								}
								setCatChanged(catChanged.slice(0))
							}}
							options={listOptions}
							value={[catChanged[posLine].to? {value: catChanged[posLine].to, label: catChanged[posLine].to} : null]}
							// value={[defaultO? {value: defaultO.subcategory, label: defaultO.improvedName} : null]}
							placeholder={"Feed Prop"}
							isSearchable={true}
							isMulti={false}
						/>
					</div>
				}) : ""
			}
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategoryChanged({
					"from": "from",
					"to": "to"
				}, dbKey, e, catChanged)}}>Save</button>
			</div>
		</div>
	}

	const renderCategoryHausToEioMapping = () => {

		let idSection = 2,
			dbKey = "category_haus_to_eio"

		if(idSection != catFromTo.id_section)	return;

		let brandListOptions = [],
			countAlreadyAsssociated = 0

		console.info("catFromcatFromcatFromcatFrom", catFromTo.from);
		console.info("catTocatTocatTocatTocatTocatTo", catFromTo.to);

		let listCatIdToName = {}
		catFromTo.to.forEach(o => {
			listCatIdToName[o.last.id_category] = o.inline;
			brandListOptions.push({
				value: o.last.id_category,
				label: o.inline
			})
		})
		catFromTo.from.forEach(o => {
			if("eio_id_associated" in o)
				countAlreadyAsssociated++
		})

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Category Mapping from hausoftools to eio [{countAlreadyAsssociated}/{catFromTo.from.length}]
			</div>
			<div className="">
				{
					catFromTo.from.map((o, posLine) => {
						return <div key={`${o.id}_${refConst.id_store_hausfTools}_category_haus_to_eio`} className="p-2 d-flex flex-row align-items-center w-100">
							<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"}} */>{o.name}</div>
							<div className="input-group w-50">
								<div className={`input-group-prepend ` + (posLine > 0 && "eio_id_associated" in catFromTo.from[posLine-1] && catFromTo.from[posLine-1].eio_id_associated? "" : "d-none")}>
									<button className="btn btn-outline-secondary" title="Copy the value from the line up" onClick={() => {
										catFromTo.from[posLine].eio_id_associated = catFromTo.from[posLine-1].eio_id_associated
										setRefreshView(refreshView+1)
									}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-up position-relative" style={{top: "-3px"}} viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M4.854 1.146a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L4 2.707V12.5A2.5 2.5 0 0 0 6.5 15h8a.5.5 0 0 0 0-1h-8A1.5 1.5 0 0 1 5 12.5V2.707l3.146 3.147a.5.5 0 1 0 .708-.708l-4-4z"/>
										</svg>
									</button>
								</div>
								<Select
									isClearable
									styles={refConst.styleList}
									className=""
									onChange={(selectedOption) => {
										console.info('selectedOption', selectedOption);
										o.eio_id_associated = !selectedOption? null : selectedOption.value
										setRefreshView(refreshView+1)
									}}
									options={brandListOptions}
									// value={ o.last.name in dataMapping? [{value: dataMapping[o.last.name], label: dataMapping[o.last.name]}] : (o.eio_id_associated? [{value: o.eio_id_associated, label: o.eio_id_associated}] : null) }
									value={ (o.eio_id_associated? [{value: o.eio_id_associated, label: listCatIdToName[o.eio_id_associated]}] : null) }
									placeholder={"category from eio"}
									isSearchable={true}
									isMulti={false}
								/>
							</div>
						</div>
					})
				}
			</div>
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategory({
					"id_category": "from",
					"eio_id_associated": "to"
				}, dbKey, e)}}>Save</button>
			</div>
		</div>
	}

	const renderDatasetHausToEioMapping = () => {
		// Haus to Eio
		let idSection = 3,
			dbKey = "dataset_haus_to_eio",
			countAlreadyAsssociated = 0,
			brandListOptions = [],
			listCatIdToName = {}

		if(idSection != catFromTo.id_section)	return;

		console.info("catFromcatFromcatFromcatFrom", catFromTo.from);
		console.info("catTocatTocatTocatTocatTocatTo", catFromTo.to);

		catFromTo.from.forEach(o => {
			if("eio_id_associated" in o){
				countAlreadyAsssociated++
			}
		})
		
		catFromTo.to.map(o => {
			listCatIdToName[o.last.id] = o.inline;
			brandListOptions.push({
				value: parseInt(o.last.id),
				label: o.inline
			})
		})

		return <div className={catFromTo.id_section === idSection? "" : "d-none"}>
			<div className="alert alert-primary mb-3" role="alert">
				Dataset Mapping From hausoftools to eio [{countAlreadyAsssociated}/{catFromTo.from.length}]
			</div>
			<div className="">
				{
					catFromTo.from.map((o, posLine) => {

						return <div key={`${o.last.id}_${refConst.id_store_hausfTools}_dataset_haus_to_eio`} className="p-2 d-flex flex-row align-items-center w-100">
							<div className="mr-3 w-50 text-wrap" /* style={{minWidth: "200px"}} */>{o.inline}</div>
							<div className="input-group w-50">
								<div className={`input-group-prepend ` + (posLine > 0 && "eio_id_associated" in catFromTo.from[posLine-1] && catFromTo.from[posLine-1].eio_id_associated? "" : "d-none")}>
									<button className="btn btn-outline-secondary" title="Copy the value from the line up" onClick={() => {
										catFromTo.from[posLine].eio_id_associated = catFromTo.from[posLine-1].eio_id_associated
										setRefreshView(refreshView+1)
									}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-up position-relative" style={{top: "-3px"}} viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M4.854 1.146a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L4 2.707V12.5A2.5 2.5 0 0 0 6.5 15h8a.5.5 0 0 0 0-1h-8A1.5 1.5 0 0 1 5 12.5V2.707l3.146 3.147a.5.5 0 1 0 .708-.708l-4-4z"/>
										</svg>
									</button>
								</div>
								<Select
									isClearable
									styles={refConst.styleList}
									className=""
									onChange={(selectedOption) => {
										o.eio_id_associated = !selectedOption? null : selectedOption.value
										setRefreshView(refreshView+1)
									}}
									options={brandListOptions}
									// value={o.title in dataMapping? [{value: dataMapping[o.title], label: dataMapping[o.title]}] : (o.eio_id_associated? [{value: o.eio_id_associated, label: o.eio_id_associated}] : null) }
									value={(o.eio_id_associated? [{value: o.eio_id_associated, label: listCatIdToName[o.eio_id_associated]}] : null) }
									placeholder={"Dataset from eio"}
									isSearchable={true}
									isMulti={false}
								/>
							</div>
						</div>
					})
				}
			</div>
			<div className="p-0 d-flex flex-row align-items-center justify-content-center fixed-bottom" style={{height: "0px"}}>
				<button type="button" className="btn btn-lg btn-success position-relative shadow" style={{bottom: "30px"}} onClick={(e) => {submitChangeCategory({
					"last": "from",
					"eio_id_associated": "to"
				}, dbKey, e)}}>Save</button>
			</div>
		</div>
	}

	if(!props.securityClearance(props))	return "";


	
	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container">
			<div className="dropdown mb-4">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
					Walmart
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
					<button className="dropdown-item" type="button" onClick={() => { loadSection(8) }}>Referral Fees</button>
					<button className="dropdown-item" type="button" onClick={() => { loadSection(9) }}>Categories V4</button>
				</div>
			</div>
			<div className="dropdown mb-4">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
					Select mapping to update the auto creator
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
					<button className="dropdown-item" type="button" onClick={() => { loadSection(1) }}>Category eio to eBay</button>
					<button className="dropdown-item" type="button" onClick={() => { loadSection(2) }}>Category haus to eio</button>
					<button className="dropdown-item" type="button" onClick={() => { loadSection(3) }}>DataSet haus to eio</button>
					<button className="dropdown-item" type="button" onClick={() => { loadSection(6) }}>Auto Create to feed properties</button>
				</div>
			</div>
			<div className="dropdown mb-4">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false" style={{backgroundColor: "red"}}>
					Select mapping to update for 3M
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
					{/* <button className="dropdown-item" type="button" onClick={() => {setSectionOpen(4)}}>Category 3M to eBay</button> */}
					<button className="dropdown-item" type="button" onClick={() => { loadSection(5) }}>Category 3M to EIO</button>
					<button className="dropdown-item" type="button" onClick={() => { loadSection(7) }}>Category 3M to Haus</button>
				</div>
			</div>
			{renderCategoryEioToEbayMapping()}
			{renderCategoryHausToEioMapping()}
			{renderDatasetHausToEioMapping()}
			{/* {renderCategory3MToEbayMapping()} */}
			{renderCategory3mToEioMapping()}
			{renderCategory3mToHausMapping()}
			{renderCategoryAutoCreateToFeedProp()}
			{renderReferralWalmart()}
			{renderCategoriesWalmart()}
		</div>

	);
}