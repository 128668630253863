// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { parseAjaxReturn, ajaxPerso, fetchMenu } from '../fnc'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import store from 'store2';
const refConst = require("../constants.js").v


function ShopifyCollection(props){
	
	const [listCollect, setListCollect] = useState([]);
	const [searched, setSearched] = useState([]);

	const stores = refConst.stores.filter(o => {
		if(o.id_marketplace == 14)	return o
		return false
	})
	
	let objPreSelected = store("storeSelectedShCol")
	const [shopSelected, setShopSelected] = useState(objPreSelected? objPreSelected : stores[0]);

	console.log("shopSelectedshopSelected", shopSelected);
	console.log("listCollectlistCollect", listCollect);

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		store("storeSelectedShCol", shopSelected)

		if(shopSelected && shopSelected.id)
			fetchMenu( {
				"shop": shopSelected.id,
				"menuOrNot": true
			}, () => {
				props.loading(false)
			}, (r) => {
				console.log("Fetched collection:", r);
				if(r.success){
					let results = r.data
					setListCollect(results)
				}else{
					props.info({error: r.error})
				}
			});

		/* fetchCollection( (r) => {
			props.loading(false)
			console.log("Fetched collection:", r);
			if(r.success){
				let results = r.data
				setListCollect(results)
			}
		}) */
	}, [shopSelected])

	const fetchCollection = (callback) => {
		
		props.loading(true)
		ajaxPerso( {
			"api": "eio",
			"trigger": "getMenu",
			"shop": shopSelected.id
		}, callback);
	}

	const submitForm = (e) => {
		e.preventDefault();

		let objToSend = listCollect.map(obj => {
			return {
				"id_category": obj.id_category,
				"is_menu": obj.is_menu,
				"level": obj.level,
				"name": obj.name
			}
		})

		console.log("objToSend", objToSend);
		//return false;

		if(objToSend.length > 0){
			ajaxPerso( {
				"api": "eio",
				"trigger": "insertOrUpdateMenu",
				"shop": shopSelected.id,
				"data": JSON.stringify(objToSend)
			}, (r) => {
				console.info("submitForm:", r);
				
				if(r.success){
					props.info({success: parseAjaxReturn(r.res)})
				}else{
					props.info({error: parseAjaxReturn(r.res)})
				}
			})
		}else{
			console.info('Please fill the input');
		}
	}

	const loadAndSaveCollections = (e) => {
		e.preventDefault();

		if(!shopSelected || !shopSelected.id)	return

		props.loading(true)

		ajaxPerso( {
			"api": "shopify",
			"trigger": shopSelected.id == refConst.id_store_eio? "downloadCollectionEIO" : "downloadCollectionHOT"
		}, (r) => {
			console.info("res shopify:", r);
			props.loading(false)
			let msgInfoOut = {}
			if(r.error)
				msgInfoOut.error = r.error
			else
				msgInfoOut.success = `Successfully changed ${r.resInsert.res.processed || r.resInsert.res.updated}, deleted: ${r.resInsert.res_cleaning? r.resInsert.res_cleaning.processed : '0'}`
			props.info(msgInfoOut)
		})
	}

	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" defaultValue={ JSON.stringify(shopSelected) } onChange={(e) => { setShopSelected(JSON.parse(e.target.value)) }}>
					{
						stores.map(obj => {
							if(obj.id_marketplace == 14)
								return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Search</span>
				</div>
				<input type="text" className="form-control" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => { setSearched(e.target.value) }}/>
			</div>
			<div>
				<button type="button" className="btn btn-outline-secondary" onClick={loadAndSaveCollections}>Load and Save All Collections</button>
				<button type="button" className="btn btn-primary ml-4" onClick={submitForm}>Save</button>
			</div>
			<div className="w-100">
				<ul className="list-group list-group-flush w-100">
					<li className="list-group-item d-flex flex-row align-items-center justify-content-between">
						<span className="font-weight-bold">Name:</span>
						<span className="font-weight-bold">Menu collection</span>
					</li>
					{
						listCollect.map(obj => {

							if(searched && !(new RegExp(searched, 'i')).test(obj.name))
								return false

							return <li key={obj.id_category + "_" + obj.id + "keyLCollect"} className="list-group-item d-flex flex-row align-items-center justify-content-between">
								<span>({obj.id}) - {obj.name}</span>
								<span>
									<div className="custom-control custom-switch noselect mb-2">
										<input type="checkbox" className="custom-control-input" id={"customSwitch_"+obj.id} checked={parseInt(obj.is_menu) === 1? true : false} onChange={(e) => {
											obj.is_menu = e.target.checked? 1 : 0
											setListCollect(listCollect.slice(0))
										}}/>
										<label className="custom-control-label" htmlFor={"customSwitch_"+obj.id}></label>
									</div>
								</span>
							</li>
						})
					}
				</ul>
			</div>
			
		</div>

	);
}

export default ShopifyCollection;