import React, { Component } from 'react';
import {
	BrowserRouter as Router,// eslint-disable-line
	Switch, // eslint-disable-line
	Route,
	Link, // eslint-disable-line
	Redirect, // eslint-disable-line
	NavLink
} from 'react-router-dom';
import FastEdit from './Pages/ctn/FastEdit_form';
import Compare from './Pages/Compare';
import Repricer from './Pages/Repricer';
import Shopify_promo from './Pages/Shopify_promo';
// import ShopifyOriginUpdate from './Pages/ShopifyOriginUpdate.js';
import ShopifyAlterData from './Pages/ShopifyAlterData.js';
import Backup from './Pages/Backup';
import Graphique from './Pages/Graphique';
import AvgShipping from './Pages/Avg_shipping';
import Ebay from './Pages/Ebay';
import Admin from './Pages/Account';
import AutoCreate_settings from './Pages/AutoCreate_settings';
import MappingBrand from './Pages/mappingBrand';
import Shopify_dt from './Pages/shopify_dt';
import Shopify_tag from './Pages/shopify_tag';
import Shopify_draft from './Pages/shopify_draft';
// import Aplus from './Pages/Aplus';
import AplusV2 from './Pages/AplusV2';
import ImagesShopify from './Pages/ImagesShopify';
import BuyXGetY from './Pages/BuyXGetY';
import ShopifyBulkFile from './Pages/shopifyBulkFile';
import ShopifyCollectionChanger from './Pages/ShopifyCollectionChanger';
import Logs from './Pages/logs';
import HistorySells from './Pages/historySells';
import EbayListing from './Pages/ctn/eBay/listing/EbayListing';
import ShopifyListing from './Pages/shopifyListing';
import Template from './Pages/template';
import Brand from './Pages/brand';
import ShopifyCollection from './Pages/shopifyCollection';
import Catalog from './Pages/Catalog';
import Crontask from './Pages/Crontask';
import Infrastructure from './Pages/Infrastructure';
import Carrier from './Pages/Carrier';
import ModalFtpFeed from './Pages/ctn/ModalFtpFeed';
import ImgCanvaser from './Pages/ctn/ImgCanvaser';
import FileConvertor from './Pages/ctn/FileConvertor';
import AutoCreate from './Pages/ctn/autoCreate/autoCreate';
import ManufacturerMain from './Pages/ctn/manufacturer/ManufacturerMain';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

var moment = require('moment');

//import { warn } from '@firebase/database/dist/src/core/util/util';

/*
function handleClick(e) {
	e.preventDefault();
	console.log('The link was clicked.');
}*/

var adminKey = "-MjVxK86GVBUKL5oNU66";

class MainMenu extends Component {

	constructor(props) {
		super(props)
		this.state = {
			permissions: this.props.getUser() && this.props.getUser().permissions? this.props.getUser().permissions : []
		}

		this.getMenu = this.getMenu.bind(this);
		this.getLink = this.getLink.bind(this);
	}

	getMenu(){
		return {
			"walmart": {
				"Update Prices": {
					"id": 1,
					"group": [
						adminKey
					],
					"link": "/FastEdit/1",
					"route": "/FastEdit/:pageId",
					"obj": () => {
						return <FastEdit
							{...this.props}
							group={this.getMenu().walmart["Update Prices"].group}
						></FastEdit>},
					"index": true,
					"props": [
						"info"
					],
					"hidden": true
				},/*
				"Add items": {
					"group": [adminKey],
					"link": "/FastEdit/2",
					"route": false,
					"obj": false,
					"hidden": true
				},
				"Compare": {
					"group": [adminKey],
					"link": "/FastEdit/3",
					"route": false,
					"obj": false
				},
				"Add price list": {
					"group": [adminKey],
					"link": "/FastEdit/4",
					"route": false,
					"obj": false,
					"hidden": true
				}, */
			},
			"Main": {
				"Update Finale Qty": {
					"id": 22,
					"group": [
						adminKey,
						"-MjWDdyYa7UJHb-U4XwU", // finale_write
					],
					"link": "/FastEdit/5",
					"route": false,
					"obj": false
				},
				"Catalog": {
					"id": 21,
					"group": [
						adminKey,
						"-MjWDd5MXTvRcLkDQl64", // finale_read
					],
					"nameUi": <span>
						<FontAwesomeIcon
							icon={faHome}
							style={{marginRight: "10px"}}
						/>Catalog</span>,
					"link": "/Catalog",
					"route": "/Catalog/:itemId?",
					"obj": () => <Catalog
						{...this.props}
						group={this.getMenu().Main["Catalog"].group}
					></Catalog>
				},
				"Manufacturer": {
					"isBeta": false,
					"id": 56,
					"nameUi": <span>
						<i className="bi bi-bar-chart-fill" style={{marginRight: "10px"}}></i>
						Manufacturer {(moment() < moment("2024-06-01")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"group": [
						adminKey,
						"-NwkJFulcOob3wcg_ZLh" // special_manufacturing_read
					],
					"hidden": false,
					"isRedirect": true,
					"link": "/manufacturer",
					"route": "/manufacturer/:idBrand?",
					"obj": (parent) => {
						console.info("this.getMenu()", this.getMenu());
						return <ManufacturerMain
							{...this.props}
							parent={parent}
							group={this.getMenu().Main["Manufacturer"].group}
						></ManufacturerMain>
					}
				}
			},
			"Shopify": {
				"Shopify Item Changer": {
					"id": 54,
					"isBeta": false,
					"group": [
						adminKey,
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"nameUi": <span>Collection Changer{(moment() < moment("2023-12-31")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"link": "/shopify_item_mover",
					"route": "/shopify_item_mover",
					"obj": () => { return <ShopifyCollectionChanger
						title={"Shopify Item Mover from Collection A to Collection B"}
						{...this.props}
						group={this.getMenu().Shopify["Shopify Item Changer"].group}
					/>}
				},
				"ShopifyBulkFile": {
					"id": 46,
					"nameUi": <span>Product Bulk file {(moment() < moment("2021-11-20")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"group": [
						adminKey,
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"hidden": false,
					"link": "/shopify_bulk_file",
					"route": "/shopify_bulk_file/:fncName?/:id?",
					"obj": (parent) => { return <ShopifyBulkFile
						{...this.props}
						parent={parent}
						group={this.getMenu().Shopify["ShopifyBulkFile"].group}
					/> }
				},
				/* "Shopify Origin": {
					"id": 41,
					"group": [
						adminKey,
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"nameUi": <span>Shopify Origin {(moment() < moment("2021-11-01")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"link": "/shopify_origin",
					"route": "/shopify_origin",
					"obj": () => { return <ShopifyOriginUpdate
						{...this.props}
						group={this.getMenu().Shopify["Shopify Origin"].group}
					/>},
				}, */
				"Shopify Bulk": {
					"id": 50,
					"group": [
						adminKey,
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"nameUi": <span>Product Bulk Prop{(moment() < moment("2022-04-01")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"link": "/shopify_bulk",
					"route": "/shopify_bulk",
					"obj": () => { return <ShopifyAlterData
						title={"Shopify Bulk Property Update"}
						{...this.props}
						group={this.getMenu().Shopify["Shopify Bulk"].group}
					/>}
				},
				"Shopify promo": {
					"id": 35,
					"group": [
						adminKey,
						"-Mj_tL_BeXYP4sYC2EUi", // promo_access
					],
					"nameUi": <span>Promo {(moment() < moment("2021-08-01")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"link": "/shopify_promo",
					"route": "/shopify_promo",
					"obj": () => { return <Shopify_promo
						{...this.props}
						group={this.getMenu().Shopify["Shopify promo"].group}
					/>},
				},
				/* "Aplus": {
					"id": 44,
					"nameUi": <span>A+</span>,
					"group": [
						adminKey,
						"-MjWFVGky1zywg_XWuXV", // aplus_delete
						"-MjWFT026EiVCG7YuSl8", // aplus_read
						"-MjWFTo6mm3cEvCZp1Xg" // aplus_write
					],
					"hidden": true,
					"link": "/Aplus",
					"route": "/Aplus/:fncName?/:id?",
					"obj": (parent) => { return <Aplus
						{...this.props}
						parent={parent}
						group={this.getMenu().Shopify["Aplus"].group}
					/> }
				}, */
				"AplusV2": {
					"id": 48,
					"nameUi": <span>A+ v2</span>,
					"group": [
						adminKey,
						"-MjWFVGky1zywg_XWuXV", // aplus_delete
						"-MjWFT026EiVCG7YuSl8", // aplus_read
						"-MjWFTo6mm3cEvCZp1Xg" // aplus_write
					],
					"hidden": false,
					"link": "/AplusV2",
					"route": "/AplusV2/:fncName?/:id?/:idVersion?",
					"obj": (parent) => { return <AplusV2
						{...this.props}
						parent={parent}
						group={this.getMenu().Shopify["AplusV2"].group}
					/> }
				},
				"Images": {
					"id": 52,
					"nameUi": <span>Images</span>,
					"group": [
						adminKey,
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"hidden": false,
					"link": "/Images",
					"route": "/Images/:fncName?/:id?/:idVersion?",
					"obj": (parent) => { return <ImagesShopify
						{...this.props}
						parent={parent}
						group={this.getMenu().Shopify["AplusV2"].group}
					/> },
					"isBeta": false
				},
				"BuyXGetY": {
					"id": 53,
					"nameUi": <span>Buy X Get Y</span>,
					"group": [
						adminKey,
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"hidden": false,
					"link": "/BuyXGetY",
					"route": "/BuyXGetY/:fncName?/:id?/:idVersion?",
					"obj": (parent) => { return <BuyXGetY
						{...this.props}
						parent={parent}
						group={this.getMenu().Shopify["BuyXGetY"].group}
					/> },
					"isBeta": true
				},
			},
			"Divers": {
				"backup": {
					"id": 7,
					"link": "/Backup",
					"route": "/Backup",
					"obj": () => { return <Backup
						{...this.props}
						group={this.getMenu().Divers["backup"].group}
					></Backup>},
					"hidden": true
				}
			},
			"Tools": {
				"AutoCreate": {
					"id": 51,
					"group": [
						adminKey,
						"-NsKsFX4KoU9daC-oZ7L",
					],
					"isRedirect": true,
					"link": "/auto_create",
					"route": "/auto_create/:ctn?",
					"hidden": false,
					// "isDemo": true,
					"isBeta": true,
					"nameUi": <span>Auto Creator</span>,
					"obj": (parent) => { return <AutoCreate 
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["AutoCreate"].group}
					></AutoCreate> }
				},
				"Forecaster": {
					"id": 8,
					"group": [
						adminKey,
						"-MjVTrM8OYAasUfiw-r9", // forecaster_access
					],
					"isRedirect": true,
					"link": "/forecaster",
					"route": "/forecaster",
					//"obj": () => { return <Redirect to="http://forecaster.io/index.html" />} 
					"obj": () => { document.cookie = "eio_security_point=true; Domain=ehub.work; Path=/"; window.open("https://ff.ehub.work", '_blank'); return false;} 
				},
				"Forecaster v2": {
					"id": 57,
					"group": [
						adminKey,
						"-MjVTrM8OYAasUfiw-r9", // forecaster_access
					],
					"isRedirect": false,
					"hidden": true,
					"link": "/forecaster_v2",
					"route": "/forecaster_v2",
					//"obj": () => { return <Redirect to="http://forecaster.io/index.html" />} 
					"obj": (parent) => { return <AutoCreate 
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["AutoCreate"].group}
					></AutoCreate> }
				},
				"Graphique From DB": {
					"id": 9,
					"group": [
						adminKey
					],
					"isRedirect": true,
					"hidden": true,
					"link": "/graphique",
					"route": "/graphique/:idStore?",
					"obj": (parent) => { return <Graphique
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Graphique From DB"].group}
					></Graphique> },
					"isBeta": true
				},
				"AVG Shipping cost": {
					"id": 10,
					"group": [
						adminKey,
						"-Mj_haqzismzadLiujp1", // order_view
					],
					"isRedirect": true,
					"link": "/avg_shipping",
					"route": "/avg_shipping/:idShipping?",
					"obj": (parent) => { return <AvgShipping
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["AVG Shipping cost"].group}
					></AvgShipping> },
					"isBeta": false
				}/* ,
				"eBay": {
					"id": 11,
					"isRedirect": true,
					"link": "/eBay",
					"route": "/eBay/:idShipping?",
					"obj": (parent) => { return <Ebay {...this.props} parent={parent}></Ebay> },
					"isBeta": true
				} */,
				"Account": {
					"id": 12,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/Account",
					"route": "/Account/:idUser?",
					"obj": (parent) => { return <Admin
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Account"].group}
					/> }
				},
				"AutoCreate_settings": {
					"id": 30,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/AutoCreate_settings",
					"route": "/AutoCreate_settings/:idUser?",
					"obj": (parent) => { return <AutoCreate_settings
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["AutoCreate_settings"].group}
					/> }
				},
				"ShopifyDt": {
					"id": 13,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/Shopify_dt",
					"route": "/Shopify_dt/",
					"obj": (parent) => { return <Shopify_dt
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["ShopifyDt"].group}
					/> }
				},
				"ShopifyTag": {
					"id": 40,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/Shopify_tag",
					"route": "/Shopify_tag/:idGrpOpen?/:idTagOpen?",
					"obj": (parent) => { return <Shopify_tag
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["ShopifyTag"].group}
					/> }
				},
				"ShopifyDraft": {
					"id": 49,
					"group": [
						adminKey
					],
					"hidden": false,
					"nameUi": <span>Shopify Draft {(moment() < moment("2022-03-10")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"link": "/Shopify_draft",
					"route": "/Shopify_draft/:idGrpOpen?/:idTagOpen?",
					"obj": (parent) => { return <Shopify_draft
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["ShopifyDraft"].group}
					/> }
				},
				"MappingBrand": {
					"id": 15,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/MappingBrand",
					"route": "/MappingBrand/",
					"obj": (parent) => { return <MappingBrand
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["MappingBrand"].group}
					/> }
				},
				"Brand": {
					"id": 16,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/brand",
					"route": "/brand/:idBrand?",
					"obj": (parent) => { return <Brand
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Brand"].group}
					/> }
				},
				"Template": {
					"id": 17,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/template",
					"route": "/template/:idObjOpen?",
					"obj": (parent) => { return <Template
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Template"].group}
					/> }
				},
				"ShopifyCollection": {
					"id": 18,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/shopifycollection",
					"route": "/shopifycollection/:idObjOpen?",
					"obj": (parent) => { return <ShopifyCollection
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Template"].group}
					/> }
				},
				"Logs": {
					"id": 19,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/Logs",
					"route": [
						"/Logs/:idObjOpen?",
						"/Logs/type/:type?/:level?",
					],
					"obj": (parent) => { return <Logs
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Logs"].group}
					/> }
				},
				"HistorySells": {
					"id": 20,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/HistorySells",
					"route": "/HistorySells/:idObjOpen?",
					"obj": (parent) => { return <HistorySells
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["HistorySells"].group}
					/> }
				},
				"Compare": {
					"id": 34,
					"group": [
						adminKey,
						"-MjVTgFHd2akT44hrjKg", // ebay_eio_write
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
						"-MjWGqGi59xNbduybZHA", // walmart_eio_write
						"-MjWHEUEG0HJvoZtpe19", // amazon_eio_write
					],
					"link": "/catalog_compare",
					"route": "/catalog_compare",
					"obj": () => { return <Compare
						{...this.props}
						group={this.getMenu().Tools["Compare"].group}
					/>},
				},
				"Repricer": {
					"id": 45,
					"group": [
						adminKey,
						"-MmU5eTNRDF_8C9PP35e", // reprice_access
						/* "-MjVTgFHd2akT44hrjKg", // ebay_eio_write
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
						"-MjWGqGi59xNbduybZHA", // walmart_eio_write
						"-MjWHEUEG0HJvoZtpe19", // amazon_eio_write */
					],
					"link": "/repricer",
					"route": "/repricer",
					"obj": () => { return <Repricer
						{...this.props}
						group={this.getMenu().Tools["Repricer"].group}
					/>},
				},
				"CronTask": {
					"id": 36,
					"group": [
						adminKey
					],
					"link": "/crontask",
					"route": "/crontask",
					"obj": () => { return <Crontask
						{...this.props}
						group={this.getMenu().Tools["CronTask"].group}
					/>},
				},
				"Infrastructure": {
					"id": 39,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/infrastructure",
					"route": "/infrastructure",
					"obj": () => { return <Infrastructure
						{...this.props}
						group={this.getMenu().Tools["Infrastructure"].group}
					/>},
				},
				"Carrier": {
					"id": 47,
					"group": [
						adminKey
					],
					"hidden": true,
					"link": "/carrier",
					"route": "/carrier/:idCarrierOpen?/:idRateOpen?",
					"obj": (parent) => { return <Carrier
						{...this.props}
						parent={parent}
						group={this.getMenu().Tools["Carrier"].group}
					/>},
				}
			},
			"Listing": {
				"Shopify": {
					"id": 14,
					"group": [
						adminKey,
						"-MjVTXBQweEPcKSCGTUH", // shopify_haus_delete
						"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
						"-MjWGrJZhs3iSCRBw00j", // walmart_eio_delete
						"-MjH_9MuY646871R7lUA", // shopify_eio_write
					],
					"isRedirect": true,
					"link": "/shopify",
					"route": "/shopify/:idItem?",
					"obj": (parent) => { return <ShopifyListing
						{...this.props}
						parent={parent}
						group={this.getMenu().Listing["Shopify"].group}
					></ShopifyListing> }
				},
				"ebay": {
					"isBeta": false,
					"id": 37,
					"nameUi": <span>eBay {(moment() < moment("2021-03-09")? <span className="badge badge-info ml-2">New</span> : '')}</span>,
					"group": [
						adminKey,
						"-MjVTgFHd2akT44hrjKg", // ebay_eio_write
						"-MjVThc5pOvGWLuvi5f1", // ebay_eio_delete
					],
					"isRedirect": true,
					"link": "/ebayListing",
					"route": "/ebayListing/:idItem?",
					"obj": (parent) => { return <EbayListing
						{...this.props}
						parent={parent}
						group={this.getMenu().Listing["ebay"].group}
					></EbayListing> }
				}
			},
			"Download": {
				"eBay app": {
					"id": 42,
					"group": [
						adminKey,
						"-MjWPvGjs-zQ5HuKzmgQ", // app_download
					],
					"isRedirect": false,
					"link": "/eioApp/eio-eBay Setup 1.0.0.exe",
					"download": true,
				},
				"From FTP": {
					"id": 43,
					"nameUi": <ModalFtpFeed {...this.props} className="nav-item nav-link pointer" title={<span>
						From FTP {moment() < moment("2021-01-30")? <span className="badge badge-info ml-2">New</span> : ''}
					</span>}/>,
					"group": [
						adminKey,
						"-MjVTkV8Bbpm074D5AuV", // feed_download
					],
					"isRedirect": false,
				}
			},
			"Media": {
				"Canvaser": {
					"id": 43,
					"nameUi": <ImgCanvaser {...this.props} className="nav-item nav-link pointer" title={<span>
						Framer {moment() < moment("2021-07-30")? <span className="badge badge-info ml-2">New</span> : ''}
					</span>}/>,
					"isRedirect": false,
				},
				"File Convertor": {
					"id": 55,
					"nameUi": <FileConvertor {...this.props} className="nav-item nav-link pointer" title={<span>
						File Convertor
					</span>}/>,
					"isRedirect": false,
				}
			}
		}
	}

	getLink(props){

		
		let linksTop = [],
			admin = [],
			menu = this.getMenu(),
			level = this.props.getLevel(),
			levels = this.props.getLevels(),
			// routes = [<Route key="0" exact path='/'><Redirect to="/FastEdit/1" /></Route>]; // Default page <Redirect key="0" exact from="/" to="/FastEdit/1" />
			routes = []; // Default page <Redirect key="0" exact from="/" to="/FastEdit/1" />
			////routes = [<Route key="0" exact path='/' render={menu.walmart["Update Prices"].obj}/>]; // Default page <Redirect key="0" exact from="/" to="/FastEdit/1" />
		
		var menuOut = [];
		
		let idMenuFocused = level? levels[level].index : false

		if(this.state.permissions.length === 0) return {"ctn": routes, "menuTop": linksTop, "menuLeft": menuOut, "admin": admin};

		admin.push(<NavLink key="menuAdmin_link_HistorySells" to={menu.Tools.HistorySells.link} className="dropdown-item">History Sells</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Logs" to={menu.Tools.Logs.link} activeClassName="active" className="dropdown-item">Logs</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Admin" to={menu.Tools.Account.link} className="dropdown-item">Account</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Shopify_dt" to={menu.Tools.ShopifyDt.link} className="dropdown-item">Shopify DT</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Shopify_tag" to={menu.Tools.ShopifyTag.link} className="dropdown-item">Shopify TAG</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_MappingBrand" to={menu.Tools.MappingBrand.link} className="dropdown-item">Mapping Brand</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Template" to={menu.Tools.Template.link} className="dropdown-item">Template</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Brand" to={menu.Tools.Brand.link} className="dropdown-item">Brand</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_ShopifyCollection" to={menu.Tools.ShopifyCollection.link} className="dropdown-item">Shopify Collection</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_CronTask" to={menu.Tools.CronTask.link} className="dropdown-item">CronTask</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Infrastructure" to={menu.Tools.Infrastructure.link} className="dropdown-item">Infrastructure</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_Carrier" to={menu.Tools.Carrier.link} className="dropdown-item">Carrier</NavLink>);
		admin.push(<NavLink key="menuAdmin_link_AutoCreate_settings" to={menu.Tools.AutoCreate_settings.link} className="dropdown-item">Auto Create Settings</NavLink>);

		let index = false;
		for (const sectionTitle in menu) {
			if (menu.hasOwnProperty(sectionTitle)) {
				let linksMain = []
				let subMenu = menu[sectionTitle]
				
				for (const title in subMenu) {
					if (subMenu.hasOwnProperty(title)) {
						const dataLink = subMenu[title];

						// Define the default page depending the level of access.
						if(idMenuFocused && dataLink.id === idMenuFocused){
							index = <Route key="0" exact path='/' render={dataLink.obj}/>
						}

						/* console.info('Permission', this.state.permissions);
						console.info('dataLink.group', dataLink.group); */

						// -----------  Permissions -------------
						
						let authorized = !dataLink.group? true : false;

						if(dataLink.group)
							this.state.permissions.forEach(pUid => {
								if(dataLink.group && dataLink.group.indexOf(pUid) !== -1){
									authorized = true;
									return;
								}
							});

						if(("hidden" in dataLink && dataLink.hidden) || !authorized){
							//continue
						}else{

							let isBeta = "isBeta" in dataLink && dataLink.isBeta? <span className="badge badge-danger ml-3">beta</span> : ''
							let isDemo = "isDemo" in dataLink && dataLink.isDemo? <span className="badge badge-warning ml-3">demo</span> : ''

							if(!dataLink.link){ // For Modal
								linksTop.push(<div key={title} className="breadcrumb-item">{ title }</div>) //  className="active" //  onClick={handleClick}
								linksMain.push(<div key={title} className="list-group-item p-0">{ "nameUi" in dataLink? dataLink.nameUi : title }</div>) //  className="active" //  onClick={handleClick}
							}else if(dataLink.download){ // For Download
								linksTop.push(<div key={title} className="breadcrumb-item"><a download={true} href={dataLink.link}>{ title }</a></div>) //  className="active" //  onClick={handleClick}
								linksMain.push(<div key={title} className="list-group-item p-0"><a download={true} href={dataLink.link} className="nav-item nav-link">{ "nameUi" in dataLink? dataLink.nameUi : title }{isBeta}{isDemo}</a></div>) //  className="active" //  onClick={handleClick}
							}else{ // Normal JS link
								linksTop.push(<div key={title} className="breadcrumb-item"><NavLink to={dataLink.link} activeClassName="active">{ title }</NavLink></div>) //  className="active" //  onClick={handleClick}
								linksMain.push(<div key={title} className="list-group-item p-0"><NavLink to={dataLink.link} className="nav-item nav-link" activeClassName="bg-secondary text-light">{ "nameUi" in dataLink? dataLink.nameUi : title }{isBeta}{isDemo}</NavLink></div>) //  className="active" //  onClick={handleClick}
							}
						}
							
						if(dataLink.route)
							//if("isRedirect" in dataLink && dataLink.isRedirect)
							routes.push(<Route exact key={title} path={dataLink.route} data={props} render={dataLink.obj}/>) //  className="active" //  onClick={handleClick}
					}
				}

				if(linksMain.length > 0)
					menuOut.push(<div key={sectionTitle} className="list-group">
						<h6 className="dropdown-header text-white bg-dark">{sectionTitle}</h6>
						<div className="list-group">
							{linksMain}
						</div>
					</div>)
			}
		}

		routes.push(index);
		return {"ctn": routes, "menuTop": linksTop, "menuLeft": menuOut, "admin": admin};
	}
}

export default MainMenu;
