// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { List, arrayMove } from 'react-movable';
import {
	NavLink
} from 'react-router-dom';
import {UnControlled as CodeMirror} from 'react-codemirror2'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import JSONPretty from 'react-json-pretty';
import {ajaxPerso} from "../fnc.js"
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');

require('codemirror/mode/javascript/javascript.js');
require('codemirror/keymap/sublime');
const refConst = require("../constants.js").v
var moment = require('moment');


function Logs(props){

	const [logsList, setLogsList] = useState({});
	const [logsError, setLogsError] = useState([]);
	const [logsWarning, setLogsWarning] = useState([]);
	const [logsInfo, setLogsInfo] = useState([]);
	const [openedItem, setOpenedItem] = useState(false);
	const [from, setFrom] = useState(new Date());
	const [to, setTo] = useState(false);

	var viewLogsObj = {
		"error": {
			"colorClass": "bg-danger"
		},
		"warning": {
			"colorClass": "bg-warning"
		},
		"info": {
			"colorClass": "bg-info"
		}
	}

	useEffect(() => {
		if(!props.securityClearance(props))	return "";
		loadLogs(filterDate())
	}, [from, to])

	/* useEffect(() => {
		let idLog = props.parent.match.params.idObjOpen
		
		setOpenedItem(logsList.find(obj => {
			if(parseInt(obj.id) === parseInt(idLog))	return obj
		}))

	}, [props.parent.match.params.idObjOpen, logsList])
 */
	useEffect(() => {
		console.warn("props.parent.match.params", props.parent.match.params);
	})

	const filterDate = () => {
		let startAt = false,
			endAt = false;

		// day
		//console.log(this.state.from + "==" + this.state.to, moment(this.state.from).format("YYYY-MM-DD") == moment(this.state.to).format("YYYY-MM-DD"));		
		/* if(from && to){
			startAt = moment(from).format("YYYY-MM-DD 00:00");
			endAt = moment(to).format("YYYY-MM-DD 23:59");
		} */
		if(from){
			startAt = moment(from).format("YYYY-MM-DD 00:00");
			endAt = moment(from).format("YYYY-MM-DD 23:59");
		}

		return (startAt && endAt)? {
			startAt: startAt,
			endAt: endAt
		} : false
	}

	const loadLogs = (args, callback) => {

		props.loading(true);

		fetchLogs(args, (r) => {

			if(r.success && r.res.length > 0){
				console.info("Fetch_logs:", r.res);

				let groupPerDataType = {};

				r.res.forEach(obj => {
					let className = obj.class? obj.class : 'No Class';

					if(!groupPerDataType[obj.level])
						groupPerDataType[obj.level] = {[className + ' - ' + obj.datatype]: []}


					if(!groupPerDataType[obj.level][className + ' - ' + obj.datatype])
						groupPerDataType[obj.level][className + ' - ' + obj.datatype] = []

					obj.style = viewLogsObj[obj.level]
					groupPerDataType[obj.level][className + ' - ' + obj.datatype].push(obj)
				})

				console.log("SORTEDgroupPerDataType", groupPerDataType);
				
				setLogsList( groupPerDataType )

				/* let listError = []
				r.res.forEach(obj => {
					if(obj.level === "error")
						listError.push(obj)
				})
				setLogsError( listError )

				let listWarning = []
				r.res.forEach(obj => {
					if(obj.level === "warning")
						listWarning.push(obj)
				})
				setLogsWarning( listWarning )

				let listInfo = []
				r.res.forEach(obj => {
					if(obj.level === "info")
						listInfo.push(obj)
				})
				setLogsInfo( listInfo ) */
			}
			props.loading(false);
		})
	}

	const fetchLogs = (args, callback) => {
		console.log("TEST", filterDate());
		
		ajaxPerso( Object.assign({
			"trigger": "get_log",
			"api": "eio",
		}, args), callback);
	}

	const showLogs = (args, callback) => {
		let groupPerDataType = [];
		
		console.info('logsList', logsList);

		let params = props.parent.match.params

		
		
		for (const level in logsList) {
			if (logsList.hasOwnProperty(level)) {

				let output = []
				
				if(params.type && level === params.level){
					if(!logsList[level][params.type]){
						// If we change the date in the callendar and had a previous val selected, if that val is not existing, we need to redirect.
						props.parent.history.push('/Logs')
					}else{
						logsList[level][params.type].forEach(obj => {
							output.push( <li key={obj.id + "_idObj"} className={(viewLogsObj[level].colorClass) + " list-group-item"} onClick={(e) => {

								if($(e.target).is("li"))
									props.parent.history.push('/Logs')
							}}>
								<div className={(viewLogsObj[level].colorClass) + ""}>
									<JSONPretty theme={JSONPrettyMon} data={JSON.stringify(obj.message)}></JSONPretty>
									{/* <CodeMirror
										value={JSON.stringify(obj.message)}
										options={{
											mode: 'javascript',
											theme: 'monokai',
											lineNumbers: true,
											extraKeys: {"Ctrl-Space": "autocomplete"},
											keymap: "sublime",
											indentWithTabs: true,
											lineWrapping: true
										}}
										onChange={(editor, data, value) => {
											//obj.value = value
										}}
									/> */}
									<span className="ml-4">ID: {obj.id} - {obj.date}</span>
								</div>
							</li>)
						});
					}
				}else{

					for (const type in logsList[level]) {
						if (logsList[level].hasOwnProperty(type)) {
	
							let objType = logsList[level][type]
							output.push( <li key={type + "_type"} className={(viewLogsObj[level].colorClass) + " list-group-item"} onClick={() => {
								if(objType.length > 8000){
									props.info({error: "Too much entry on that one."})
								}else{
									props.parent.history.push('/Logs/type/' + type + "/" + level)
								}
							}}>
								<div>{objType.class? `[${objType.class}]` : ''} {type}<span className="badge badge-light ml-4">{objType.length}</span></div>
							</li>)
						}
					}
				}
				groupPerDataType.push(<ul key={level + "_level"} className="w-50 list-group text-body m-3" style={{maxHeight: "450px", overflowY: "auto", overflowX: "hidden"}}>
					{output}
				</ul>)
			}
		}

		return(<div className="w-100">
			<div className={(params.type || params.level? "" : "d-none") + " border p-2"}>
				<button type="button" className="float-left btn btn-primary mr-3" onClick={() => {
					props.parent.history.push('/Logs')
				}}>Close</button>
				{ params.type? <div>Selected: <span className="font-weight-bold">{ params.type }</span></div> : "" }
				{ params.level? <div>This element of type: <span className="font-weight-bold">{ params.level }</span></div> : "" }
			</div>
			<div className="d-flex flex-row">
				{groupPerDataType}
			</div>
		</div>)

		/* if(objView.obj.length > 0)
				return <div key={objView.type} className={(objView.colorClass) + " card text-white mb-3 ml-3 mr-3"} style={{"width": "18rem"}}>
					<div className="card-header h5">
						{ objView.type.charAt(0).toUpperCase() + objView.type.slice(1) }
						<span className="ml-3 badge badge-light">{ objView.obj.length }</span>
					</div>
					<div className="card-body">
						<div className="card-text">
							<ul className="list-group text-body" style={{maxHeight: "250px", overflowY: "auto", overflowX: "hidden"}}>
								{
									objView.obj.map(obj => {
										return <li key={obj.id} className="list-group-item" onClick={() => {
											props.parent.history.push('/Logs/' + obj.id)
										}}>
											<div>{obj.datatype}</div>
											<div>{obj.date}</div>
										</li>
									})
								}
							</ul>
						</div>
					</div>
				</div> */

	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			{
				!props.parent.match.params.idObjOpen? <div className="w-100">
					<div className="d-flex flex-row justify-content-center">
						{moment(from).format("YYYY-MM-DD")}
						<DayPicker
							// className="Selectable"
							// selectedDays={[from, { from, to }]}
							selectedDay={from}
							modifiers={{
								highlighted: from
							}}
							// modifiers={{ start: from, end: to }}
							onDayClick={(day, { selected }) => {
								if (selected) {
									// Unselect the day if already selected
									setFrom(undefined)
									return;
								}
								setFrom(day);
							}}
							/*
							<DayPicker
								className="Selectable"
								selectedDays={[from, { from, to }]}
								modifiers={{ start: from, end: to }}
								onDayClick={(day) => {
									let range = DateUtils.addDayToRange(day, {
										from: from,
										to: to
									});

									setFrom(range.from)
									setTo(range.to)
								}}
							*/
							onMonthChange={(month) => {
								console.log("mounth", month);
								let monthObj = moment(month).format("YYYY-MM-DD")
							}}/>
						<div>
							<button type="button" className="btn btn-primary" onClick={() => {
								loadLogs(filterDate())
							}}>Refresh data</button>
						</div>
					</div>
					<div className="d-flex justify-content-center">
						{
							showLogs()
						}
					</div>
				</div> : (openedItem? <div className="w-100">
					<NavLink to={"/Logs"}><button type="button" className="btn btn-lg btn-primary mr-3">Go to Logs</button></NavLink>
					<div className="mt-3 w-100">
						<CodeMirror
							value={JSON.stringify(openedItem.message)}
							options={{
								mode: 'javascript',
								theme: 'monokai',
								lineNumbers: true,
								extraKeys: {"Ctrl-Space": "autocomplete"},
								keymap: "sublime",
								indentWithTabs: true,
								lineWrapping: true
							}}
							onChange={(editor, data, value) => {
								//obj.value = value
							}}
						/>
					</div>
				</div> : "")
			}
		</div>

	);
}

export default Logs;