// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { capitalizeFLetter, ajaxPerso } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

const refConst = require("../../constants.js").v

const defaultFrameSize = 1500,
	defaultFrameSizeShowed = 200

export default function ImgCanvaser(props){

	const initialState = {
		items: [],
		submited: false,
		img: null,
		version: "1.0.0",
		rotate: 0,
		darkBkg: false
	}

	const inputImg = useRef(null)
	const widthF = useRef(null)
	const heightF = useRef(null)
	const range = useRef(null)
	const convertOutput = useRef(null)

	const [submited, setSubmited] = useState(initialState.submited);
	const [img, setImg] = useState(initialState.img);
	const [items, setItems] = useState(initialState.items.slice(0));
	const [version, setVersion] = useState(initialState.version);
	const [widthFrame, setWidthFrame] = useState(defaultFrameSize);
	const [heightFrame, setHeightFrame] = useState(defaultFrameSize);
	const [widthSizeImgView, setWidthSizeImgView] = useState(defaultFrameSizeShowed);
	const [heightSizeImgView, seHeightSizeImgView] = useState(defaultFrameSizeShowed);
	const [rotate, setRotate] = useState(initialState.rotate);
	const [darkBkg, setDarkBkg] = useState(initialState.darkBkg);

	useEffect(() => {
		triggerModal(true);
	}, [submited, img, widthSizeImgView, heightSizeImgView, rotate, darkBkg])

	/* useEffect(() => {
		if(modalOpened)
			triggerModal(false)
	}, [modalOpened]) */

	useEffect(() => {
		changeRatio()
	}, [img])

	useEffect(() => {
		if(inputImg.current && inputImg.current.files.length > 0)
			sendImage()
	}, [rotate])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let changeRatio = () => {

		if(!widthF.current || !heightF.current)	return;

		let w = defaultFrameSizeShowed,
			h = defaultFrameSizeShowed
		
		let ratio_orig = parseInt(widthF.current.value)/parseInt(heightF.current.value);

		if (defaultFrameSizeShowed/h > ratio_orig) {
			w = h*ratio_orig;
		}else{
			h = w/ratio_orig;
		}

		setWidthSizeImgView(w);
		seHeightSizeImgView(h);
	}

	const rotateImage = (currentRotation, degrees) => {
		let newRotation = (currentRotation + degrees) % 360;
		if (newRotation < 0) {
			newRotation += 360;
		}
		console.info("NewValue", newRotation);
		return newRotation;
	};

	let showImgMenu = () => {

		return <div>
			<div className="d-flex flex-row mb-4">
				<div className="input-group mr-2 ml-2">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-frame-height">Height</span>
					</div>
					<input type="number" className="form-control h-100" placeholder="Frame H" aria-describedby="basic-frame-height" ref={heightF} style={{width: "100px"}} defaultValue={heightFrame}/>
				</div>
				<div className="input-group mr-2 ml-2">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-frame-width">Width</span>
					</div>
					<input type="number" className="form-control h-100" placeholder="Frame W" aria-describedby="basic-frame-width" ref={widthF} style={{width: "100px"}} defaultValue={widthFrame}/>
				</div>
				<div className="input-group mr-2 ml-2 d-flex flex-row flex-nowrap">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-frame-width">Rotate<FontAwesomeIcon icon={faSyncAlt} style={{fontSize: "18px"}} className="ml-2"/></span>
					</div>
					<button type="button" className="btn btn-outline-secondary rounded-0 p-2" onClick={() => {setRotate(rotateImage(rotate, -90))}}>-90</button>
					<button type="button" className="btn btn-outline-secondary rounded-0 p-2" onClick={() => {setRotate(rotateImage(rotate, 90))}}>+90</button>
					<button type="button" className="btn btn-outline-secondary rounded-0 p-2" onClick={() => {setRotate(rotateImage(rotate, -45))}}>-45</button>
					<button type="button" className="btn btn-outline-secondary rounded-0 p-2" onClick={() => {setRotate(rotateImage(rotate, 45))}}>+45</button>
				</div>
				<div className="input-group">
					<label htmlFor="customRangeZoomOut" className="form-label">Zoom out</label>
					<input type="range" className="form-range" id="customRangeZoomOut" ref={range} min="-100" max="100" step="10" defaultValue="0"></input>
				</div>
				<div className="input-group mr-2 ml-2">
					<button type="button" className="btn bg-info text-light" onClick={() => {sendImage()}}>Generate</button>
				</div>
			</div>
			<div className="d-flex flex-row mr-2 ml-3" title="Only to see some white logo/text">
				<div className="custom-control custom-switch noselect mb-3">
					<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={darkBkg} onChange={(e) => { setDarkBkg(e.target.checked) }}/>
					<label className="custom-control-label" htmlFor="customSwitchFree">Dark Background</label>
				</div>
				<div className="groupFormModified ml-3 mb-3">
					<span>Convert to</span>
					<select ref={convertOutput}>
						<option value={''}>Original</option>
						<option value={'needConvertionToPng'}>PNG &#40;Icon, Banner&#41;</option>
						<option value={'needConvertionToJpeg'}>JPEG &#40;Product images&#41;</option>
					</select>
				</div>
			</div>
		</div>
	}

	
	let sendImage = (callback) => {
		
		if(!inputImg.current || inputImg.current.files.length === 0){
			props.info({error: "Please select a file."})
			return;
		}

		let convertFnc = convertOutput.current? convertOutput.current.value : null
		console.info('inputImg', inputImg.current.files);
		console.info('convertOutput', convertFnc);

		var formData = new FormData();
		//imgList.forEach((img, i) => {
		let mimeFile = inputImg.current.files[0].type
		formData.append(`imgList`, inputImg.current.files[0], inputImg.current.files[0].name);
		//})
		formData.append("api", "eio");
		formData.append("trigger", "img_canvaser");

		formData.append("widthFrame", widthF.current? widthF.current.value : widthFrame);
		formData.append("heightFrame", heightF.current? heightF.current.value : heightFrame);
		formData.append("darkBkg", darkBkg);
		if(convertFnc)
			formData.append(convertFnc, true);

		if(rotate)
			formData.append("rotate", rotate);

		if(range.current){
			let rangeVal = parseInt(range.current.value)
			formData.append("zoomOut", rangeVal);
		}
		
		props.loading(true)
		setSubmited(true)

		if(inputImg.current)
			ajaxPerso(formData, function(res){
				console.info('Fetched `img_canvaser`', res);
				setSubmited(false)
				props.loading(false)
				setImg({
					name: inputImg.current.files[0].name,
					mime: mimeFile,
					base64: res
				})

			}, function(jqXHR, textStatus, errorThrown) {
				props.info({error: textStatus})
				console.warn( textStatus );
				setSubmited(false)
				props.loading(false)
			}, {
				contentType: false,
				processData: false,
				cache: false,
				async: true,
				timeout: 60000,
			});
	}


	let triggerModal = (refresh) => {
		
		props.modal({
			show: refresh? false : true,
			title: <span>Image Framer <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);
				
				return <div>
					<div className="alert alert-info smallText" role="alert">
						Add a frame, center your picture and let you adjust some setting. (Click on &lsquo;generate&rsquo; after each change)<br/>
						This tools keep the color profil of the original picture only if no conversion is applied.
					</div>
					<div className="alert alert-warning smallText" role="alert">
						JPEG should be privilegized when the picture is intended to be used as a product image.<br/>PNG should be used for icon, banner, logo, etc.<br/> <u>Limited to 50MB</u>
					</div>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text" id="inputGroupFileImg">Upload</span>
						</div>
						<div className="custom-file">
							<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileImg" ref={inputImg} onChange={() => {
								sendImage(() => {
									// close()
								})
							}}/>
							<label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
						</div>
					</div>
					<div>
						<div className="d-flex flex-row">
							{
								img? <div className="w-100">
									{showImgMenu()}
									{/*<img src={'data:'+img.mime+';base64,' + btoa(unescape(encodeURIComponent(img.bin)))} width={200} height={200}/> : 'No image'*/}
									<div className="text-center mt-4 mb-4"><img src={'data:'+img.mime+';base64,' + img.base64} width={widthSizeImgView} height={heightSizeImgView} className={"border " + (darkBkg? "bg-dark" : "")}/></div>
								</div> : 'No image'
							}
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				// setModalOpened(false)
				resetPage()
				close()
			}
			, 
			ok: {
				title: submited? "Please wait" : (img? "Download" : "Generate"),
				fnc: (popup, close) => {
					if(!submited){
						if(img){
							var a = document.createElement("a"); //Create <a>
							// console.info('atob(img.base64)', atob(img.base64));

							let mime = "application/octet-stream";
							let extension = img.name.split(".")[1]
							if(convertOutput.current && convertOutput.current.value === "needConvertionToPng"){
								mime = "image/png"
								extension = "png"
								img.name = img.name.replace(/\.(jpg|jpeg|png|gif|bmp)$/i, ".png")
							}else if(convertOutput.current && convertOutput.current.value === "needConvertionToJpeg"){
								mime = "image/jpeg"
								extension = "jpeg"
								img.name = img.name.replace(/\.(jpg|jpeg|png|gif|bmp)$/i, ".jpeg")
							}

							console.info("mimemime", mime);
							a.href = "data:"+mime+";base64," + img.base64; //Image Base64 Goes here
							a.download = img.name.split(".")[0] + "." + extension; //File name Here
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
						}else{
							sendImage(() => {
								// close()
							})
						}
					}
				}
			},
			return: {
				title: submited? "Please wait" : "Close",
				fnc: (popup, close) => {
					// setModalOpened(false)
					resetPage()
					close()
				}
			}
		});
	}

	return (
		<div className={("className" in props? props.className : 'list-group-item list-group-item-action p-2 text-center')} onClick={() => 	triggerModal(false)}>
			{
				props.title
			}
		</div>
	);
}