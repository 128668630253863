// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
// eslint-disable-next-line no-unused-vars
import StyledDropzone from '../../shopify/dropZone'
// eslint-disable-next-line no-unused-vars
import EbayForm from './EbayForm'
// eslint-disable-next-line no-unused-vars
import EbayView from './EbayView'
import EbayImgPushAndView from './EbayImgPushAndView'
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import Select from 'react-select';
import $ from 'jquery';
import store from 'store2';
import { fetchBrands, fetchStores } from '../../../../fnc'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment';

import {
	NavLink
} from 'react-router-dom';
import {ajaxPerso} from "../../../../fnc.js"

const refConst = require("../../../../constants.js").v
const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

const initialState = {
	stores: [],
	loadedBinList: [],
	alsoCreateFilesFor: [],
	binList: [],
	binListDropzone: [],
	images: [],
	imagesDropzone: [],
	filesDropzone: [],
	files: [],
	msgInfo: [],
	brands: [],
	countries: [],
	extendedBody: {},
	searched: "",
	searchedOld: "",
	searchResult: [],
	dataSet: [],
	imgSelected: [],
	calculatedFieldsDt: {},
	updateMode: false,
	isAutoCreated: null,

	grpDataSetSelected: false,
	navGrpSelected: false,
	navGrpSelectedMenuRight: 'Select Images',

	history: [],
	isEnded: false,
}

function EbayListing(props){

	const smallWidthWindow = useMediaQuery({
		query: '(max-device-width: 1024px)'
	})

	const [shopList, setShopList] = useState([
		{
			name: "eBay - EIO",
			shopName: "eBay - EIO",
			storeId: refConst.id_store_eBay_EIO,
			selected: true,
			fnc: (args) => {
				return <EbayView {...args}/>;
			}
		}/* ,{
			name: "dev",
			storeId: 7,
			shopName: "brandnameapp",
			fnc: (args) => {
				//return <EbayView {...args}/>;
			}
		} */
	]);

	const [msg, setMsg] = useState({
		success: false,
		successColor: "#A2E8BB",
		error: false,
		errorColor: "#FF917D"
	});

	let objPreSelected = store("shopSelectedEbay")

	if(!objPreSelected)
		objPreSelected = shopList.find(obj => {
			if(obj.selected)
				return obj
		})
			
	const [shopSelected, setShopSelected] = useState(objPreSelected);

	const [stores, setStores] = useState([...initialState.stores]);
	const [imgSelected, setImgSelected] = useState(initialState.imgSelected.slice(0));
	const [loadedBinList, setLoadedBinList] = useState([...initialState.loadedBinList]);
	const [alsoCreateFilesFor, setAlsoCreateFilesFor] = useState([...initialState.alsoCreateFilesFor]);
	const [binList, setBinList] = useState([]);
	const [binListDropzone, setBinListDropzone] = useState([]);
	const [images, setImages] = useState([]); // Image loaded from ebay bank
	const [imagesDropzone, setImagesDropzone] = useState([...initialState.imagesDropzone]);
	const [filesDropzone, setFilesDropzone] = useState([...initialState.filesDropzone]);
	const [updateMode, setUpdateMode] = useState(initialState.updateMode); // get the item object with metafields to update
	const [isAutoCreated, setIsAutoCreated] = useState(initialState.isAutoCreated); // get the item object with metafields to update


	const [proposalHidden, setProposalHidden] = useState(false);
	const [version, setVersion] = useState("2.0.0");

	const [brands, setBrands] = useState(initialState.brands);

	const [countries, setCountries] = useState(initialState.countries);

	const [msgInfo, setMsgInfo] = useState(initialState.msgInfo);

	const [extendedBody, setExtendedBody] = useState(initialState.extendedBody);

	const [searched, setSearched] = useState(initialState.searched);
	const [searchedOld, setSearchedOld] = useState(initialState.searchedOld);
	const [searchResult, setSearchResult] = useState(initialState.searchResult);

	const [originlDataSet, setOriginalDataSet] = useState(initialState.dataSet.slice(0));
	const [dataSet, setDataSet] = useState(initialState.dataSet);
	const [grpDataSetSelected, setGrpDataSetSelected] = useState(initialState.grpDataSetSelected);
	const [navGrpSelected, setNavGrpSelected] = useState(initialState.navGrpSelected);
	const [navGrpSelectedMenuRight, setNavGrpSelectedMenuRight] = useState(initialState.navGrpSelectedMenuRight);
	//const [dsData, setDsData] = useState(initialState.dsData);
	const [calculatedFieldsDt, setCalculatedFieldsDt] = useState(initialState.calculatedFieldsDt);

	const [history, setHistory] = useState(initialState.history);
	const [isEnded, setIsEnded] = useState(initialState.isEnded);

	var menuRight = [{
		title: 'Select Images',
		body: () => <div>
			<div className={binList.filter(o => {
				if(o.typeBin == "img")	return o
			}).length > 0? "border pt-3" : ""}>
				<StyledDropzone setDropzone={setBinListDropzone} binList={binList} info={props.info} type="img"/>
				{ showSelectedImg() }
			</div>
			<EbayImgPushAndView {...props} loadImg={loadImg} images={images} imgSelected={imgSelected} setImgSelected={setImgSelected} updateMode={updateMode}/>
		</div>
	}];

	useEffect(() => {
		$('.hasTooltip').tooltip('dispose')

		$('.hasTooltip[data-toggle="tooltipFormError"]').tooltip({
			placement: "bottom",
			trigger: "manual",
			template: '<div class="tooltip" role="tooltip"><div class="arrow arrow_red"></div><div class="tooltip-inner bg-danger"></div></div>'
		}).tooltip('show').tooltip('update')

		return() => {
			// When disconnected, we remove all active tooltip.
			$('.hasTooltip').tooltip('dispose')
		}
	}, [msgInfo])

	useEffect(() => {
		fetchStores((r) => {
			console.info('Fetched Stores:', r);
			if(r.success)
				setStores(r.data)
		})

		fetchCountryList( (r) => {
			props.loading(false)
			console.log("Fetched countries", r);
			if(r.success){
				let results = r.data.sort()
				setCountries([false].concat(results))
			}
		})
		loadUserHistory()
	}, [])

	useEffect(() => {
		if(stores.length > 0 && updateMode.list_id_store){
			setAlsoCreateFilesFor(stores.filter((o, i) => {
				if(o.id_marketplace == refConst.id_marketplace_shopify && o.id != shopSelected.storeId && updateMode.list_id_store.indexOf(o.id) !== -1)	return o
			}).map(o => o.id))
		}
		
	}, [stores, shopSelected, updateMode])

	useEffect(() => {
		/**
		 * Fetching:
		 * 	- Menu
		 * 	- Brand
		 */
		if(shopSelected){
			
			fetchBrands( shopSelected.storeId, {}, null, (r) => {
				props.loading(false)
				console.log("Fetched brands", r);
				if(r.success){
					let results = r.data.sort()
					setBrands(results)
				}
			})
		}

		store("shopSelectedEbay", shopSelected)

	}, [shopSelected])
	
	useEffect(() => {
		if(searched === searchedOld){
			initiateSearch()
		}
	}, [searched, shopSelected])

	const initiateSearch = () => {
		if(searched){
			console.info('updated Search result');

			searchRequest({
				title: searched,
				limit: 20
			}, (r) => {
				console.info('Fetched search ebay: ', r);
				props.loading(false)
				setSearchResult(r.data)
			})
		}else
			setSearchResult([])
	}

	useEffect(() => {
		var idItem = props.parent.match.params.idItem;
		loadImg();
		if(idItem){
			console.info('Load item data itemId:', idItem);
			// Overwrite each data with the new item informations
			loadItem(idItem)
		}else{
			// Clean the view
			setUpdateMode(initialState.updateMode)
		}
		
	}, [props.parent.match.url])

	useEffect(() => {
		console.info("images", binList);
		console.info("imagesDropzone", binListDropzone);
		
		let bin = binList.slice(0)
		let countPerGrp = {};

		binList.forEach(o => {
			if(!(o.typeBin in countPerGrp))
				countPerGrp[o.typeBin] = 0;
			countPerGrp[o.typeBin]++;
		})

		console.info('countPerGrp', countPerGrp);

		for (let i = 0; i < binListDropzone.length; i++) {
			if(!(binListDropzone[i].typeBin in countPerGrp))
				countPerGrp[binListDropzone[i].typeBin] = 0;

			binListDropzone[i].pos = countPerGrp[binListDropzone[i].typeBin]++
			bin.push(binListDropzone[i])
		}
		setBinList(bin)
		
	}, [binListDropzone])

	const loadImg = (callback) => {
		fetchImages({}, (r) => {
			console.info('REQ fetchImages:', r);
			setImages(r.data)
		})
	}

	const fetchImages  = (obj, callback) => {

		ajaxPerso( Object.assign({
			"api": "ebay",
			"trigger": "fetchImages",
			"idAcc": props.getUser().id
		}, obj), callback)
	}

	useEffect(() => {
		/**
		 * Pre select the first group and the first dataGroup associated once we change the dataSet
		 */
		if(dataSet.length === 0)	return
		
		/**
		 * try to open the group previously selected
		 */
		let objFoundGrp = null

		if(grpDataSetSelected)
			objFoundGrp = dataSet.find(o => {
				if(parseInt(o.obj.id) === parseInt(grpDataSetSelected))	return o
			})

		console.info('setGrpDataSetSelected', objFoundGrp? objFoundGrp.obj.id : dataSet[0].obj.id);
		setGrpDataSetSelected(parseInt(objFoundGrp? objFoundGrp.obj.id : dataSet[0].obj.id))
		console.info('setNavGrpSelected', objFoundGrp && objFoundGrp.childs.length > 0? objFoundGrp.childs[0].obj.id : dataSet[0].childs[0].obj.id);
		setNavGrpSelected(parseInt(objFoundGrp && objFoundGrp.childs.length > 0? objFoundGrp.childs[0].obj.id : dataSet[0].childs[0].obj.id))
	}, [dataSet, grpDataSetSelected])


	const emptyForm = () => {
		console.info('Cleaning form: ebayListing');
		// setImages(initialState.images)
		setImagesDropzone(initialState.imagesDropzone.slice(0))
		setFilesDropzone(initialState.filesDropzone.slice(0))
		setGrpDataSetSelected(initialState.grpDataSetSelected);
		setNavGrpSelected(initialState.navGrpSelected);
		//console.log("Cleaning test ", originlDataSet);
		setDataSet(JSON.parse(JSON.stringify(originlDataSet)))
		setMsgInfo(initialState.msgInfo)
		setImgSelected(initialState.imgSelected.slice(0))
		setIsAutoCreated(initialState.isAutoCreated)
		loadImg();
	}

	const loadHistory = (uid, callback) => {

		if(!uid)	return false

		ajaxPerso( {
			"api": "eio",
			"trigger": "get_history_insert",
			"shop": shopSelected.storeId,
			"uid": uid,
			'dateFrom': moment().local().format('YYYY-MM-DD 00:00:00'),
			'dateTo': moment().local().format('YYYY-MM-DD 23:59:59')
		}, callback)
	}

	const loadUserHistory = () => {

		loadHistory( props.getUser().uid, (r) => {
			console.log("Fetched history", r);
			if(r.success){
				setHistory(r.data.reverse())
			}
		})
	}

	const loadItem = (idItem) => {
		if(!idItem)	return false

		fetchItem(idItem, (r) => {
			console.info('Fetched_fetchItem: ', r);
			if(r.success){

				// Check if the item is ended
				let utcTime = r.data.ListingDetails.EndTime
				let status = r.data.SellingStatus.ListingStatus

				if("RelistedItemID" in r.data.ListingDetails && r.data.ListingDetails.RelistedItemID){
					props.parent.history.push(`/ebayListing/${r.data.ListingDetails.RelistedItemID}`);
					props.info({success: "Redirect to the new item"})
				}

				const localDate = moment.utc(utcTime).local();
				let isEndedSt = false;
				if(localDate < moment() && status == "Completed"){
					isEndedSt = true;
				}

				setIsEnded(isEndedSt)
				setUpdateMode(r.data)
			}
		})
	}

	const fetchItem = (idItem, callback) => {
		if(!idItem)	return false
		
		ajaxPerso( {
			"api": "ebay",
			"trigger": "getItemById",
			"shop": shopSelected.storeId,
			"id": idItem,
		}, callback)
	}

	const searchRequest = (obj, callback) => {

		if(!obj)	return false
		
		ajaxPerso( {
			"api": "ebay",
			"trigger": "getItems",
			"shop": shopSelected.storeId,
			"search": obj.title,
			"nbrItem": obj.limit
		}, callback)
	}

	const fetchCountryList = (callback) => {

		props.loading(true)
		ajaxPerso( {
			"api": "eio",
			"trigger": "getCountriesList"
		}, callback)
	}

	const sendImageToEbay = () => {
		let imgList = binList.filter(o => {
			if(o.typeBin == 'img')	return o;
		})

		var formData = new FormData();
		imgList.forEach((img, i) => {
			formData.append(`imgs[${i}]`, img, img.name);
		})
		formData.append("api", "ebay");
		formData.append("trigger", "uploadSiteHostedPictures");
		formData.append("idAcc", props.getUser().id);
		formData.append("shop", shopSelected.storeId);
		// formData.append("token", refConst.tokenEio);
		
		ajaxPerso(formData, function(res){
			console.info('Fetched `sendImageToEbay`', res);
			
			let imgListAdded = [],
				imgNotAdded = [];

			res.resEbay.forEach(o => {
				if(o.status){
					imgListAdded.push(o.name)
				}else	imgNotAdded.push(o.name)
			})

			loadImg();
			setBinList(initialState.binList.slice(0))
			setBinListDropzone(initialState.binListDropzone.slice(0))

			props.info({
				"success": imgListAdded.length > 0? <div>
					{imgListAdded.length} images added: {imgListAdded.join(', ')}
				</div> : null,
				"error": imgNotAdded.length > 0? <div>
					{imgNotAdded.length} images not added: {imgNotAdded.join(', ')}
				</div> : null})
		}, function(jqXHR, textStatus, errorThrown) {
			props.info({error: textStatus})
			console.warn( textStatus );
		}, {
			contentType: false,
			processData: false,
			cache: false,
			async: true,
			timeout: 60000,
		});
	}

	const showSelectedImg = () => {
		let imagesOut = []
		let imgList = binList.filter(o => {
			if(o.typeBin == 'img')	return o;
		})

		for (let i = 0; i < imgList.length; i++) {
			let obj = imgList.find(obj => {
				if(obj.pos === i)
					return obj
			})

			if(obj){
				imagesOut.push(<div className="p-3 text-center overflow-hidden" key={obj.pos + "_imgLoaded"} style={{maxWidth: "150px"}}>
					<div className="d-flex flex-row justify-content-between align-items-center bg-dark text-white mb-3">
						<div className="pl-2 pr-2">{obj.pos + 1}</div>
						<button onClick={() => removeObj(obj.pos, 'img')}>x</button>
					</div>
					<img src={obj.isFromShopify? obj.src : window.URL.createObjectURL(obj)} style={{height: "50px"}}/>
					<div className="mt-3 d-flex justify-content-center align-items-center">
						<button onClick={() => moveBinObj(false, obj.pos, "img")} type="button" className={" mr-1 btn btn-secondary pointer"}>&lt;</button>
						<button onClick={() => moveBinObj(true, obj.pos, "img")} type="button" className={" ml-1 btn btn-secondary pointer"}>&gt;</button>
					</div>
				</div>)
			}
		}

		return <div className="p-3">
			<div className={(imagesOut.length > 0? "" : "d-none" )}>
				<button type="button" className="btn btn-primary btn-lg btn-block" onClick={sendImageToEbay}>Add to image bank</button>
			</div>
			<div className="d-flex flex-row flex-wrap justify-content-center hasTooltip"  { ...(msgInfo.imgs? { "data-toggle": "tooltipFormError", "title": msgInfo.imgs } : {}) }>
				{imagesOut}
			</div>
		</div>
	}

	const moveBinObj = (moveRight, currentPos, type) => {
		// let imgs = images.slice(0)
		console.log(currentPos);
		let cp = binList.slice(0)

		let objGrp = cp.filter(o => {
			if(o.typeBin == type)	return o
		})

		// Because Filter do not garranty any order, we need to sort the element in order to have the system working.
		objGrp.sort((obj1, obj2) => {
			if(obj1.pos > obj2.pos)
				return 1
			return -1
		})

		console.info('currentPos', currentPos + " " + objGrp[currentPos].pos);

		if(moveRight){
			// Move to the head line

			if(currentPos === objGrp.length-1){
				for (let i = 0; i < objGrp.length; i++)
					objGrp[i].pos = i + 1;
				objGrp[currentPos].pos = 0;
			}else{
				let saveVal = objGrp[currentPos+1].pos
				objGrp[currentPos+1].pos = objGrp[currentPos].pos
				objGrp[currentPos].pos = saveVal
			}

		}else{
			// Move to the end

			if(currentPos === 0){
				for (let i = objGrp.length-1; i >= 0; i--)
					objGrp[i].pos = i - 1;
				objGrp[currentPos].pos = objGrp.length-1;
			}else{
				let saveVal = objGrp[currentPos-1].pos
				objGrp[currentPos-1].pos = objGrp[currentPos].pos
				objGrp[currentPos].pos = saveVal
			}
		}

		cp.sort((obj1, obj2) => {
			if(obj1.typeBin == obj2.typeBin && obj1.pos > obj2.pos)
				return 1
			return -1
		})
		
		console.log(type + " final order", objGrp);
		// console.log(type + " final order2", cp);
		
		setBinList(cp);
	}

	const removeObj = (objPos, type) => {
		if(!objPos && objPos !== 0) return false

		let objBl = binList.slice(0),
			posFound = null

		// Find the position to delete
		objBl.forEach( (o,i) => {
			if(o.pos === objPos && o.typeBin == type){
				posFound = i
				return
			}
		})
		
		objBl.splice(posFound, 1)

		// Group per type each bin object
		let objGrouped = {}
		objBl.forEach(o => {
			if(!(o.typeBin in objGrouped))
				objGrouped[o.typeBin] = []
			objGrouped[o.typeBin].push(o)
		});

		// Sort each value per 'pos'
		Object.keys(objGrouped).forEach(t => {
			
			objGrouped[t] = objGrouped[t].sort((obj1, obj2) => {
				if(obj1.pos > obj2.pos)
					return 1
				return -1
			})
	
			objGrouped[t].forEach((o, index) => {
				o.pos = index
			})
		})
		
		setBinList(objBl);
	}

	const searchDeeper = (dataSetUnorganised, obj, data, data2) => {
		if(Object.keys(data).indexOf(obj.id) === -1)
			data[obj.id] = {obj: obj, childs: {}}

		let objFound = data2.find(o => {
			if(parseInt(o.obj.id) === parseInt(obj.id))	return o
		})

		let objectA = objFound
		if(!objFound){
			data2.push({obj: obj, childs: []})
			objectA = data2[data2.length-1]
		}
		
		if(dataSetUnorganised.length > 0)
			dataSetUnorganised.forEach(obj2 => {
				if(obj.id === obj2.id_parent){
					searchDeeper(dataSetUnorganised, obj2, data[obj.id].childs, objectA.childs)
				}
			})
	}

	function searchEngine(searchedVal){
		/**
		 * We delay the search engine to search when the user is done writting and limitate the token use
		 */
		console.log(searchedVal);
		setSearchedOld(searchedVal)

		var timer = setTimeout(() => {
			setSearched(searchedVal)
			clearTimeout(timer);
		}, 500)
	}

	const loadAndshowForm = (shopName, argsForProps) => {
		
		let objShop = shopList.find(obj => {
			if(obj.name === shopName)
				return obj
		})

		if(objShop){
			return objShop.fnc(argsForProps)
		}
	}

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container">
			<div className="d-flex flex-row">
				<div className="dropdown mr-3">
					<button className="btn btn-secondary dropdown-toggle text-light" type="button" id="dropdownHistory" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						History
						<span className="badge badge-light ml-2 mr-1">{history? history.length : "0"}</span>
					</button>
					<div className="dropdown-menu p-0 overflow-auto" aria-labelledby="dropdownHistory" style={{maxHeight: "280px"}}>
						<ul className="list-group ctnUlTaskHeader">
							{
								history && history.length > 0? history.map(obj => {
									return <li key={obj.id} className="list-group-item d-flex justify-content-between align-items-center mt-0 mb-0">
										<span className="d-inline-block" style={{width: "auto"}}>
											<div className="mr-2" style={{fontSize: "11px"}}>
												<span className="mr-3">{ obj.date }</span>
												{ obj.id_product? <span className="text-primary pointer" onClick={() => {

													let storeSelected = shopList.find(o => {
														//console.log(o.api.toLowerCase() + " -- " + obj.store.toLowerCase());
														if(o.storeId === obj.store)
															return obj
													})

													//console.log(storeSelected);
													if(storeSelected){
														setShopSelected(storeSelected)
													}
													
													console.log(props.parent.history.push("/ebayListing/" + obj.id_product));

												}}>Open [{obj.ebay_sku}]</span> : '' }
											</div>
											<div className="mr-2" style={{fontSize: "12px", width: "200px", overflowY: "hidden", overflowX: "auto"}}>{ obj.title }</div>
										</span>
									</li>
								}) : ""
							}
							{/* <li className="list-group-item d-flex justify-content-between align-items-center mt-0 mb-0">
								<span className="d-inline-block" style={{width: "auto"}}>
									<div className="mr-2" style={{fontSize: "11px"}}>
										<span className="mr-3">435435 345 34 534</span>
										<NavLink to={ "/shopify/" }>Open</NavLink>
									</div>
									<div className="mr-2" style={{fontSize: "12px", width: "200px", overflowY: "hidden", overflowX: "auto"}}>f sfsdfds fgs egs re gregregregra gare gregre grea gre g</div>
								</span>
							</li> */}
						</ul>
					</div>
				</div>
				<div className="input-group mb-4">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">Website</span>
					</div>
					<select className="custom-select font-weight-bold" value={ JSON.stringify(shopSelected) } onChange={(e) => {
						$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
						setShopSelected(JSON.parse(e.target.value)) 
					}}>
						{
							refConst.stores.map(obj => {
								if(obj.id_marketplace == 144)
									return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
							})
						}
					</select>
				</div>
			</div>
			<div>
				<div className="input-group mt-2 mb-5">
					<input onClick={() => {setProposalHidden(false)}} onChange={(e) => { searchEngine(e.target.value); }} value={searchedOld} type="search" className="form-control border-dark" style={{padding: "1.675rem 0.75rem", fontSize: "1.3em"}} placeholder="Search items by title..." aria-describedby="button-search" />
					<div className="input-group-append">
						<span onClick={() => {
							searchEngine('');
							setProposalHidden(true)
						}} className={(searched.length === 0? "d-none" : "d-flex align-items-center btn btn-outline-secondary bg-info text-white")}>x</span><button className="btn btn-outline-secondary bg-dark text-white" type="button" id="button-search" onClick={(e) => { initiateSearch() }}>Search</button>
					</div>
				</div>
				<div className={proposalHidden? "d-none" : ""  + " position-relative"}>
					<ul className="list-group position-absolute shadow w-100" style={{top: "-35px", left: "0px", zIndex: "9876", maxHeight: "200px", overflowX: "auto"}}>
						{
							searchResult.map(obj => {
								return <li key={obj.id_item+"_searchResult"} className="list-group-item p-0">
									<img src={obj.picture_url} height="45px" width="45px" className="ml-3"/>
									<NavLink to={"/ebayListing/"+obj.id_item} className="p-3">[<u>{obj.sku_in_store}</u>] <span className="ml-2">{obj.title}</span></NavLink>
								</li>
							})
						}
					</ul>
				</div>
			</div>
			{
				updateMode? <div className="d-flex flex-row align-items-center mb-5">
					<NavLink to={"/ebayListing"}><button type="button" className="btn btn-lg btn-primary mr-3">Go to empty form</button></NavLink>
					<div className="d-flex flex-column">
						{
							shopSelected && shopSelected.shopName? 
								<div className="d-flex flex-column">
									<a target="_blank" rel="noopener noreferrer" href={ "https://www.ebay.com/itm/" + updateMode.ItemID} >Open item in backend</a>
									<a target="_blank" rel="noopener noreferrer" href={ updateMode.ListingDetails.ViewItemURL} >Open item on web store</a>
								</div>
								: ""
						}
					</div>
					{/* <div className="input-group flex-nowrap ml-4">
						<div className="input-group-prepend">
							<span className="input-group-text" id="addon-wrapping">Title</span>
						</div>
						<input type="text" className="form-control font-weight-bold" value={updateMode.title} readOnly/>
					</div> */}
				</div>
					: ""
			}
			<div className={(smallWidthWindow? "flex-column" : "") + " row mb-5"}>
				<div className={(smallWidthWindow? "" : "col-sm mr-4")} style={{minWidth: "550px"}}>
					<EbayForm
						{...props}
						loadedBinList={loadedBinList}
						setLoadedBinList={setLoadedBinList}
						alsoCreateFilesFor={alsoCreateFilesFor}
						binList={binList}
						setBinList={setBinList}
						setBinListDropzone={setBinListDropzone}
						images={images}
						setImages={setImages}
						setImagesDropzone={setImagesDropzone}
						files={images}
						setFilesDropzone={setFilesDropzone}
						setMsgInfo={setMsgInfo}
						brands={brands}
						countries={countries}
						//setDsData={setDsData}
						setGrpDataSetSelected={setGrpDataSetSelected}
						msgInfo={msgInfo}
						extendedBody={extendedBody}
						updateMode={updateMode}
						setProposalHidden={setProposalHidden}
						loadItem={loadItem}
						shopSelected={shopSelected}
						loadAndshowForm={loadAndshowForm}
						setNavGrpSelected={setNavGrpSelected}
						version={version}
						setVersion={setVersion}
						initialState={initialState}
						dataSet={dataSet}
						setDataSet={setDataSet}
						emptyForm={emptyForm}
						originlDataSet={originlDataSet}
						setCalculatedFieldsDt={setCalculatedFieldsDt}
						loadUserHistory={loadUserHistory}
						imgSelected={imgSelected}
						setImgSelected={setImgSelected}
						setBrands={setBrands}
						isAutoCreated={isAutoCreated}
						setIsAutoCreated={setIsAutoCreated}
						isEnded={isEnded}
						setIsEnded={setIsEnded}
					/>
				</div>
				<div className={(smallWidthWindow? "" : "col-sm ml-4") + " overflow-hidden columnImgEbay"}  style={{maxWidth:"450px"}}>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{
							menuRight.map((o) => {
								return <a key={o.title+"_keyGrpTitleNav"} onClick={() => { setNavGrpSelectedMenuRight(o.title); }} className={
									(navGrpSelectedMenuRight === o.title? "active" : "") + 
									" nav-item nav-link text-center"} id={o.title + "_navBtn"} data-toggle="tab" href={"#" + o.title + "_navCtn"} role="tab" aria-controls={o.title + "_navCtn"} aria-selected={false}>
									<div>{o.title}</div>
								</a>
							})
						}
					</div>
					<div className="tab-content" id="nav-tabContent">
						{
							menuRight.length > 0? menuRight.map((o) => {

								return <div key={o.title+"_keyGrpCtnNav"} className={(navGrpSelectedMenuRight === o.title? "active" : "") + " tab-pane fade show"} id={o.title + "_navCtn"} role="tabpanel" aria-labelledby={o.title + "_navBtn"}>
									<div className="mt-3">
										{o.body()}
									</div>
								</div>;
							}) : null
						}
					</div>
				</div>
			</div>
			<h6 className="position-fixed text-secondary" style={{right: "15px", bottom: "0px"}}>v{version}</h6>
		</div>

	);
}

export default EbayListing;