// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { capitalizeFLetter, ajaxPerso } from '../../fnc'
import Select from 'react-select';
import JSONPretty from 'react-json-pretty';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const refConst = require("../../constants.js").v

export default function HistoryItems(props){

	
	const initialState = {
		submited: false,
		results: [],
		storeIdSelected: null
	}
	
	const skuRef = useRef(null)
	const prdIdRef = useRef(null)
	const [storeIdSelected, setStoreIdSelected] = useState(null);
	const [version, setVersion] = useState("0.2.0");
	const [submited, setSubmited] = useState(initialState.submited);
	const [results, setResults] = useState(initialState.submited);
	const [logOpen, setLogOpen] = useState(initialState.logOpen);

	useEffect(() => {
		modalSheet(true);
	}, [props.stores, results, storeIdSelected, logOpen])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let fetchHistory = (args, callbackPresence) => {
		if(!args)
			throw("Please indicate a api.");
		else
			ajaxPerso( Object.assign({
				"api": "eio",
				"trigger": "get_history_insert",
			}, args), callbackPresence);
	}

	let submitForm = (e) => {
		setLogOpen(null)
		e.preventDefault();
		let args = {}		

		props.loading(true);

		if(skuRef.current.value.length > 0) args.sku = skuRef.current.value;
		if(prdIdRef.current.value.length > 0) args.id_product = prdIdRef.current.value;
		if(storeIdSelected) args.id_marketplace = storeIdSelected;

		if(Object.keys(args).length === 0){
			props.info({"error": "Please fill at least one filter."});
		}else{
			fetchHistory(args, (r) => {
				props.loading(false);
				if(r.success){
					console.info(`Fetched fetchHistory`, r);
					setResults(r.data.map(item => [item, {}]).flat());
				}else{
					props.info({"error": r.error})
				}
			})
		}
	}

	let nodeMenuFilters = (args) => {
		return <div className="container bg-light border p-3 rounded mb-3">
			<form onSubmit={submitForm}>
				<div className="d-flex flex-row justify-content-between">
					<Select
						isClearable
						styles={Object.assign(refConst.styleList, {
							container: (provided) => ({
								...provided,
								width: "200px",					
							})
						})}
						onChange={(selectedOption) => {
							setStoreIdSelected(selectedOption? parseInt(selectedOption.value) : initialState.storeIdSelected)
						}}
						options={props.stores.map(s => {
							return {
								value: s.id,
								label: s.name
							}
						})}
						// value={selectedToDel !== initialState.selectedToDel? {value: selectedToDel, label: selectedToDel} : null}
						isSearchable={true}
						isMulti={false}
						placeholder={"Store"}
					/>
					<div className="input-group" style={{width: "auto"}}>
						<div className="input-group-prepend">
							<span className="input-group-text" id="basic-idPrd">Store Product ID</span>
						</div>
						<input type="number" className="form-control" id="basic-idPrd" aria-describedby="basic-idPrd" ref={prdIdRef} />
					</div>
					<div className="input-group" style={{width: "auto"}}>
						<div className="input-group-prepend">
							<span className="input-group-text" id="basic-sku">SKU</span>
						</div>
						<input type="text" className="form-control" id="basic-sku" aria-describedby="basic-sku" ref={skuRef} />
					</div>
				</div>
				<div className="text-center p-3 pb-0 mt-3">
					<button type="submit" className="btn btn-primary" disabled={props.loading()}>Search</button>
				</div>
			</form>
		</div>
	}

	let nodeItems = (args) => {

		return results.length > 0? <div className="container bg-light border p-3 rounded mb-3">
			<table className="table table-sm">
				<thead>
					{
						<tr>
							<th scope="col">Date</th>
							<th scope="col">Account</th>
							<th scope="col">Store name</th>
							<th scope="col">Sku</th>
							<th scope="col">Action</th>
							<th scope="col">-</th>
						</tr>
					}
				</thead>
				<tbody>
					{
						results.map((r, pos) => {

							let skuItem = null
							if(r.ebay_sku) skuItem = r.ebay_sku
							if(r.shopify_sku) skuItem = r.shopify_sku
							if(r.finale_sku) skuItem = r.finale_sku
							if(r.amazon_sku) skuItem = r.amazon_sku

							if("id" in r)
								return <tr key={`${r.id}_resultHistory`} onMouseOver={() => {
									console.info('r', r);
								}}>
									<td>{ r.date }</td>
									<td>{ r.account? r.account.email : "" }</td>
									<td>{ r.store_name }</td>
									<td>{ skuItem }</td>
									<td>{ r.type }</td>
									<td><button className="btn btn-primary btn-sm" onClick={(e => {
										setLogOpen(logOpen == r.id? null : r.id)
									})}>Details</button></td>
								</tr>
							else if(results.length >= pos + 1 && (results[pos - 1].trigger || results[pos - 1].props_changed)){
								return <tr key={`${pos}_empty_resultHistory`} className={logOpen != results[pos - 1].id? "d-none" : ""} onMouseOver={() => {
									// console.info('r', r);
								}}>
									<td className="w-100" colSpan={6}>
										<div className="p-2">
											{ results[pos - 1].trigger ?? " - " }
											<button className="btn btn-danger ml-3 btn-sm" onClick={(o => {
												setLogOpen(null)
											})}>Close</button>
										</div>
										<div className="text-center w-100">
											<JSONPretty
												theme={JSONPrettyMon}
												data={results[pos - 1].props_changed}
												mainStyle="padding:1em;border-radius:10px"
												style={{
													maxHeight: "200px",
													overflow: "auto",
													margin: "auto",
													borderRadius: "15px",
													textAlign: "left",
													width: "75%",
												}}
											>
											</JSONPretty>
										</div>
									</td>
								</tr>
							}
						})
					}
				</tbody>
			</table>
		</div> : ''
	}

	console.info("logOpenlogOpen", logOpen);

	let modalSheet = (refresh) => {
		console.info('results', results);
		
		props.modal({
			show: refresh? false : true,
			title: <span>See who modified a product <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {

				return <div className="text-center">
					{ nodeMenuFilters() }
					{ nodeItems() }
				</div>
			}
			, exit: (popup, close) => {
				close()
				resetPage()
			}
			, ok: null, return: {
				title: "Exit",
				fnc: (popup, close) => {
					close()
					resetPage()
				}
			}
		});
	}
	
	return (
		<button type="button" className={(props.className || "") +  " btn btn-outline-primary"} onClick={() => {
			setLogOpen(null)
			modalSheet(props.storeTo, props.storeFrom)
		}}>
			{ props.name }
		</button>
	);
}