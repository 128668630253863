// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Filter from './filter'
import Chart from 'chart.js/auto';
import Loading from '../Loading';
import Select from 'react-select';
import {ajaxPerso} from "../../../fnc.js"
const refConst = require("../../../constants.js").v
//var moment = require('moment');

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999,
	}),
	container: (provided) => ({
		...provided,
		width: "auto",
		minWidth: "250px"
	}),
	control: provided => ({
		...provided,
		margin: 0,
		marginBottom: 0,
	})
}

var initialState = {
	_isMounted: false
}

function Coverage(props){

	const [storeSelectedInChart, setStoreSelectedInChart] = useState(false);
	const [version, setVersion] = useState("0.9.0");
	const [loadingOn, setLoadingOn] = useState(true);
	const [chart, setChart] = useState(null);
	const [coverage, setCoverage] = useState(null);
	const [itemInStock, setItemInStock] = useState(false);

	const [selectedLabel, setSelectedLabel] = useState([]);
	const [storeIds, setStoreIds] = useState([]);
	const [labels, setLabels] = useState([]);
	const [valuesConsidered, setValuesConsidered] = useState([]);
	const [colors, setColors] = useState([]);
	const [selectedFromGraph, setSelectedFromGraph] = useState(null);
	
	const [groupActiveAndInactive, setGroupActiveAndInactive] = useState(true);
	const refSelectCountryNoIn = useRef(null);

	/* const [fromTo, setFromTo] = useState({
		from: new Date(moment().local().format('YYYY-MM-DD 00:00:00')),
		to: new Date(moment().local().format('YYYY-MM-DD 23:59:59'))
	}); */

	const [top, setTop] = useState(50);
	/* const [previousSearch, setPreviousSearch] = useState({
		"to": fromTo.to, 
		"from": fromTo.from,
		"top": top,
		"itemInStock": itemInStock
	}); */

	useEffect(() => {
		initialState._isMounted = true

		return () => initialState._isMounted = false
	}, [])
	
	useEffect(() => {
		loadCoverageFetching();
	}, [])

	/* useEffect(() => {
		if(previousSearch.top != top || previousSearch.to != fromTo.to || previousSearch.from != fromTo.from || previousSearch.itemInStock != itemInStock){
			if(fromTo.to && fromTo.from){
				loadCoverageFetching();
				setPreviousSearch({
					"to": fromTo.to, 
					"from": fromTo.from,
					"top": top,
					"itemInStock": itemInStock
				})
			}
		}
	}, [top, fromTo, itemInStock]) */

	useEffect(() => {
		console.log("coverage", coverage);
		
		if(document.querySelector("#ctnChartCoverageg") && coverage){

			let storeList = {}; // Make the script faster by just looking at this index instead of going through each sku over and over
			let sumCount = 0;
			let datasets = [];
			let joinedValue = {}

			console.info('labels', labels);
			console.info('valuesConsidered', valuesConsidered);
			console.info('colors', colors);

			valuesConsidered.forEach((value, i) => {
				datasets.push( value )
			})

			if(!chart){
				
				setChart(new Chart(document.querySelector("#ctnChartCoverageg"), {
					type: 'pie',
					data: {
						"labels": labels,
						"datasets": [{
							data: datasets,
							backgroundColor: colors,
						}],
					},
					options: {
						aspectRatio: 2,
						plugins: {
							legend: {
								align: 'center',
								labels: {
									filter: filtersFnc
								}
							}
						},
						animation: {
							animateRotate : false
						},
						onClick: (a, b, c) => {
							console.info('Click on position', b[0]);
							
							if(b.length > 0){
								/* console.info('argument', a);
								console.info('labels', labels);
								console.info('valuesConsidered', valuesConsidered);
								console.info('colors', colors);
								console.info('b', b);
								console.info('argument', b[0]._chart.config.data.labels[b[0]._index]); */
								setSelectedFromGraph(Object.assign({}, b[0])) // position
							}
						}
					}
				}))
			}else{
				//console.warn("Update chart");
				//chart.destroy()
				chart.data = {
					"labels": labels,
					"datasets": [{
						data: datasets,
						backgroundColor: colors,
					}]
				};
				chart.options.plugins.legend.filter = filtersFnc;
				chart.update();
				setChart(chart)
			}
		}
	}, [coverage, storeSelectedInChart, labels, valuesConsidered])

	useEffect(() => {
		/**
		 * Triggered when we click on a part of the PIE in the Graph
		 */
		console.info('selectedFromGraph', selectedFromGraph);
		if(selectedFromGraph)
			props.setSelectedStore(storeIds[selectedFromGraph.index])

		
	}, [selectedFromGraph])

	useEffect(() => {
		if(coverage && props.stores){

			let valueConcidered = get_value_concidered()
			let labelList = [],
				valueList = [],
				colorsList = [],
				storeIdList = []

			// Split the result and create a few lists with the same index to have one line per store to show
			valueConcidered.values.forEach((obj, i) => {

				let idStore = valueConcidered.values[i].idStore
				let store = props.stores.find(o => {	if(o.id === idStore)	return o	})
				
				if(!groupActiveAndInactive)
					Object.keys(valueConcidered.values[i]).forEach(status => {
						if(['active', 'inactive'].indexOf(status) !== -1){
							colorsList.push(store? store.color : null)
							labelList.push( valueConcidered.labels[i] + " - " + status )
							valueList.push( valueConcidered.values[i][status] )
							storeIdList.push( {
								idStore: obj.idStore,
								status: status
							})
						}
					})
				else{
					storeIdList.push( {
						idStore: obj.idStore,
						status: null
					})
					colorsList.push(store? store.color : null)
					labelList.push( valueConcidered.labels[i] )
					valueList.push( valueConcidered.values[i].active + valueConcidered.values[i].inactive )
				}
			})

			// Filter from what is selectedf in the list.
			labelList.forEach((storeName, i) => {
				let listValueSelected = selectedLabel.map(o => {
					return o.value
				})

				if(listValueSelected.indexOf(storeName) !== -1){
					labelList.splice(i, 1)
					valueList.splice(i, 1)
					colorsList.splice(i, 1)
				}
			})
			
			console.info('VAL UPDATED');
			
			setColors(colorsList)
			setLabels(labelList)
			setStoreIds(storeIdList)
			setValuesConsidered(valueList)
		}
	}, [coverage, props.stores, groupActiveAndInactive, selectedLabel])

	let get_value_concidered = () => {
		let valueConcidered = [],
			labelsConcidered = []

		// ORganise the data received per store (active / inactive)
		Object.keys(coverage).slice(1).forEach(key => {
			let value = coverage[key]
			let nameInfo = key.split('_')
			let idStore = parseInt(nameInfo[0])
			let status = nameInfo[1]

			let objFound = valueConcidered.find(o => {
				if(o.idStore === idStore)	return o
			})
			
			if(!objFound){
				valueConcidered.push({
					idStore: idStore,
					active: 0,
					inactive: 0
				})
				objFound = valueConcidered[valueConcidered.length-1]
			}

			// Show only store not selected.
			objFound[status.toLowerCase()] = value;
		})

		valueConcidered.forEach(obj => {
			let store = props.stores.find(o => {
				if(o.id === obj.idStore)	return o
			})
				
			if(store){
				labelsConcidered.push(store.name)
			}
		})

		return {
			values: valueConcidered,
			labels: labelsConcidered
		}
	}

	let trigger_click_chart = () => {
		console.warn("labels", labels);
		console.warn("coverage", coverage);
	}

	let filtersFnc = (legend, chartData) => {
		if(labels.find(o => {
			//console.log(o.value + " === " + legend.text + " --- " + (o.value == legend.text));
			if(o.value == legend.text)
				return o
		}))
			return true
		return false
	}

	const loadCoverageFetching = (args) => {
		setLoadingOn(true)
		fetchData(args, (r) => {
			if(!initialState._isMounted) return false
			console.info('Fetched Coverage', r);
			
			if(r){
				setCoverage(r)
			}else{
				console.warn(props);
				
				props.info({"error": r.error})
			}
			setLoadingOn(false)
		})
	}

	const fetchData = (args, callback) => {
		
		ajaxPerso( Object.assign({
			"trigger": "get_coverage_info",
			"api": "eio",
		}, args), callback, function(jqXHR, textStatus) {
			if(!initialState._isMounted) return false

			props.info({error: textStatus})
			console.warn( textStatus );
		});
	}

	return (
		<div style={{minWidth: "400px", maxWidth: "400px"}} className={(props.className? props.className : "") + " m-auto main ctnPage d-flex flex-column flex-wrap " + (!props.integrated? " border pr-3 pl-3 pb-3 pt-1 " : "") + " position-relative"}>
			{
				!props.integrated?	<p className="h5 pt-2">Coverage</p> : ''
			}
			<div>
				<div className="d-flex flex-row flex-wrap align-items-center noselect">
					<Select
						styles={styleList}
						onChange={(selectedOption) => {
							console.log(selectedOption);
							setSelectedLabel(selectedOption)
						}}
						options={(() => {
							return labels.map(label => {
								return {
									value: label,
									label: label
								}
							})
						})()}
						value={selectedLabel}
						defaultValue={selectedLabel}
						isSearchable={true}
						isMulti={true}
						className="flex-grow-1"
					/>
					<div className="custom-control custom-switch ml-4">
						<input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(val) => {
							setSelectedLabel([])
							setGroupActiveAndInactive($(val.target).is(':checked')? true : false)
						}} checked={groupActiveAndInactive}/>
						<label className="custom-control-label" htmlFor="customSwitch1">Group Item Active and Inactive</label>
					</div>
					<div className="custom-control custom-switch ml-4">
						<input type="checkbox" checked={props.toggleNotInShop()? true : false} className="custom-control-input" id="customSwitchNotInShop" onChange={(val) => {
							// props.toggleNotInShop($(val.target).is(':checked'))

							if(refSelectCountryNoIn.current.value.length === 0)
								props.info({error: "Please select a store in the list"})

							if(!val.target.checked)
								props.toggleNotInShop(false)
							else
								props.toggleNotInShop(refSelectCountryNoIn.current.value.length > 0? refSelectCountryNoIn.current.value : false)
						}}/>
						<label className="custom-control-label" htmlFor="customSwitchNotInShop">Not in Selected Store</label>
						<select ref={refSelectCountryNoIn} className="form-control form-control-sm" onChange={(e) => {
							props.toggleNotInShop(e.target.value.length > 0? e.target.value : false)
						}}>
							<option value="">Select a store</option>
							{
								props.stores.filter(store => {
									if([
										refConst.id_store_hausfTools,
										refConst.id_store_eio,
										refConst.id_store_eBay_EIO,
										refConst.id_store_amazon_EIO,
										refConst.id_store_amazon_GOTT,
										refConst.id_store_walmart_eio
									].indexOf(store.id) !== -1)
										return store
								}).map(store => {
									return <option key={store.id} value={store.id}>{store.name}</option>
								})
							}
						</select>
					</div>
				</div>
				<div className="m-auto" style={{width: "400px"}}>
					<canvas id="ctnChartCoverageg"></canvas>
				</div>
			</div>
			<Loading loading={loadingOn} centered={true} />
			<h6 className="position-absolute text-secondary" style={{right: "15px", bottom: "0px", fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6>
			<div className="text-center smallText">Finale item count: {props.vari.total_items}</div>
		</div>

	);
}

export default Coverage;